import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import AppContext from '../AppContext/AppContext';
import { IDropdownOption, Dropdown } from '@fluentui/react/lib/Dropdown';
import { VirtualizedComboBox, IComboBox, IComboBoxOption } from '@fluentui/react/lib/ComboBox';
import { Stack } from '@fluentui/react/lib/Stack';
import { ActionButton, IconButton } from '@fluentui/react/lib/Button';
import { ISearchParams, ISearchResponse, FacetKeyLabelMap } from '../../models/ISearch';
import { DirectionalHint } from '@fluentui/react/lib/ContextualMenu';

export interface ISearchFiltersProps {
  searchParams: ISearchParams;
  searchResponse: ISearchResponse;
}

const SearchFilters: React.FC<ISearchFiltersProps> = ({ searchParams, searchResponse }) => {
  const { search } = useContext(AppContext);
  const [ isFirstQuery, setIsFirstQuery ] = useState<boolean>(true);
  const [ lastQueryText, setLastQueryText ] = useState<string>("");
  const [ selectedScope, setSelectedScope ] = useState<string>();
  const [ selectedScopeValues, setSelectedScopeValues ] = useState<string[]>([]);
  const [ activeFacets, setActiveFacets ] = useState<{[facetKey: string]: string[]}>({});
  const [ scopeOptions, setScopeOptions ] = useState<IDropdownOption[]>([]);
  const [ scopeValueOptions, setScopeValueOptions ] = useState<IDropdownOption[]>([]);
  const [ showSelectedFilters, setShowSelectedFilters ] = useState<boolean>(false);

  const getScopeOptions = (): IDropdownOption[] => {
    if (!searchResponse || !searchResponse.facets) return [];
    return Object.keys(searchResponse.facets)                  
      .filter(facetKey => searchResponse.facets[facetKey].length > 0) // Remove facet options which don't have any facet values
      .map(facetKey => ({
        key: facetKey,
        text: FacetKeyLabelMap[facetKey] || facetKey
      }));
  }

  const getScopeValueOptions = (facetKey: string): IDropdownOption[] => {
    if (!searchResponse || !searchResponse.facets || !facetKey || !searchResponse.facets[facetKey]) return [];
    return searchResponse.facets[facetKey].map(facet => ({
      key: facet.value,
      text: facet.value,
    }));
  }

  const getActiveFacetsCount = (): number => {
    let filterCount = 0;
    Object.keys(activeFacets).forEach(facetKey => filterCount += activeFacets[facetKey].length);
    return filterCount;
  }

  const setFacetValues = (facetKey: string, facetValues?: string[]) => {
    let newActiveFacets = { ...activeFacets };
    if (facetValues.length > 0) newActiveFacets[facetKey] = facetValues;
    else if (facetKey in newActiveFacets) delete newActiveFacets[facetKey];
    setActiveFacets(newActiveFacets);
    // invokeSearch();
  }

  const addScopeValueFilter = (facetKey: string, facetValue: string): void => {
    if (selectedScope === facetKey) {
      const newScopeValues = [...selectedScopeValues, facetValue ];
      setSelectedScopeValues(newScopeValues);
    }
    else {
      setFacetValues(facetKey, [ ...activeFacets[facetKey] ]);
    }
  }
  
  const removeScopeValueFilter = (facetKey: string, facetValue: string): void => {
    if (selectedScope === facetKey) {
      const newScopeValues = selectedScopeValues.filter(key => key !== facetValue);
      setSelectedScopeValues(newScopeValues);
    }
    else {
      setFacetValues(facetKey, [ ...activeFacets[facetKey] ].filter(key => key !== facetValue));
    }
  }

  const invokeSearch = () => {
    search({
      text: searchParams.text,
      activeFacets,
      visible: true,
    });
  }

  const clearFilters = () => {
    setSelectedScope(null);
    setScopeValueOptions([]);
    setSelectedScopeValues([]);
    setShowSelectedFilters(false);
    setActiveFacets({});    
  }

  // Clear filters when search query/params change
  useEffect(() => {
    if (lastQueryText !== searchParams.text) {
      if (lastQueryText !== "") {
        clearFilters();
      }
      setLastQueryText(searchParams.text);
    }
  }, [ searchParams ]);
  
  // Invoke search when active facets change
  useEffect(() => {
    if (isFirstQuery) setIsFirstQuery(false);
    else invokeSearch();

  }, [ activeFacets ]);

  // Update scope options when searchResponse changes
  useEffect(() => {
    setScopeOptions(getScopeOptions());
  }, [ searchResponse ]);

  // Update scope value options when scope changes
  useEffect(() => {
    const hasScopeWithDifferentValues = selectedScope in activeFacets && activeFacets[selectedScope] !== selectedScopeValues;
    const doesntHaveScopeAndNoSelectedValues = !(selectedScope in activeFacets) && selectedScopeValues.length > 0;

    if (hasScopeWithDifferentValues || doesntHaveScopeAndNoSelectedValues) {
      setFacetValues(selectedScope, selectedScopeValues);
    }
  }, [ selectedScopeValues ]);


  // When the Scope dropdown value changes
  const onSearchFilterScopeChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      setSelectedScope(option.key as string);
      setSelectedScopeValues(activeFacets[option.key] || []);
      setScopeValueOptions(getScopeValueOptions(option.key as string));
    }
  }

  // When the Scope Value dropdown value(s) change
  const onSearchFilterScopeValueChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
    if (option) {
      option.selected ? addScopeValueFilter(selectedScope, option.key as string) : removeScopeValueFilter(selectedScope, option.key as string);
    }
  }
  
  // When the Clear/Reset button is clicked
  const onSearchFiltersClear = () => {
    clearFilters();
  }

  const activeFacetsCount = getActiveFacetsCount();
  
  return (
    <Stack className="search-results-filters-container" tokens={{ childrenGap: 20 }}>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="end" tokens={{ childrenGap: 20 }}>
        <Stack>
          <h4>Scope</h4>
          <Dropdown 
            options={scopeOptions} 
            placeholder="Select" 
            styles={{
              dropdown: "search-results-filters-scope"
            }} 
            onChange={onSearchFilterScopeChange}
            selectedKey={selectedScope}
            calloutProps={{
              directionalHint: DirectionalHint.bottomCenter,
              directionalHintFixed: true
            }}
          />
        </Stack>
        <Stack>
          <h4>Scope Value</h4>
          <VirtualizedComboBox 
            options={scopeValueOptions} 
            placeholder="Select" 
            disabled={!selectedScope}
            styles={{
              container: "search-results-filters-scopevalues"
            }} 
            onChange={onSearchFilterScopeValueChange}
            selectedKey={selectedScopeValues}
            multiSelect={true}
            useComboBoxAsMenuWidth={true}
            calloutProps={{
              directionalHint: DirectionalHint.bottomCenter,
              directionalHintFixed: true
            }}
          />
        </Stack>
        <Stack>
          <ActionButton
            onClick={onSearchFiltersClear}
            className="search-results-filters-clear"
            ariaLabel="Clear search filters"
          >Reset</ActionButton>
        </Stack>
      </Stack>
      {activeFacetsCount > 0 && 
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontal tokens={{ childrenGap: 6 }}>
            <span>Selected Filters (<strong>{activeFacetsCount}</strong>)</span>
            <ActionButton
              onClick={() => setShowSelectedFilters(!showSelectedFilters)}
              styles={{ root: 'search-results-filters-showhide-selected' }}
            >{showSelectedFilters ? "hide" : "show"}</ActionButton>
          </Stack>
          {showSelectedFilters && 
            <Stack className="search-results-filters-selected-container" horizontal wrap tokens={{ childrenGap: '10 20' }}>
              {Object.keys(activeFacets)
                .map(facetKey => activeFacets[facetKey].map(facetValue => 
                  <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6 }}>
                    <IconButton 
                      iconProps={{ iconName: 'Cancel' }} 
                      onClick={() => removeScopeValueFilter(facetKey, facetValue)} 
                      ariaLabel={`Deselect ${FacetKeyLabelMap[facetKey] || facetKey}: ${facetValue}`}
                      title={`Deselect ${FacetKeyLabelMap[facetKey] || facetKey}: ${facetValue}`}
                      styles={{root: 'search-results-filters-deselect-button'}}                  
                    />
                    <span>{FacetKeyLabelMap[facetKey] || facetKey}: {facetValue}</span>
                  </Stack>
                ))
              }
            </Stack>
          }
        </Stack>
      }
    </Stack>
  )
}

export default SearchFilters;