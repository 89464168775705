import { useContext, useEffect } from "react";
import useAsyncData from "../../../hooks/useAsyncData";
import AppContext from "../../../components/AppContext/AppContext";
import { MISSING_RESOURCE_ERROR } from "../../../services/DataService";
import { getHrefId } from "../../../utils/LinkUtils";
import { ICDASHIG, ICDASHIGClass, ICDASHIGDomain, ICDASHIGScenario } from "../../../models/ICDASHIG";
import { INavItem } from "../../../models/INavItem";
import { ISections } from "../../../models/IDocuments";

export type CDASHIGData = {
  isLoadingItem: boolean;
  isLoadingClass: boolean;
  isLoadingItemImplements: boolean;
  isLoadingSections: boolean;
  currentItem: ICDASHIG;
  currentClass: ICDASHIGClass;
  currentDomain: ICDASHIGDomain;
  currentScenario: ICDASHIGScenario
  currentItemImplements: INavItem;
  sections: ISections
}

export default function useCDASHIGData(standard: string, version: string, productSubtype: string, classId: string, domainId: string, scenarioId: string): CDASHIGData {
  const { setError, dataService } = useContext(AppContext);
  let documentsService = dataService.igDocuments;

  // Fetch Item
  const { 
    isLoading: isLoadingItem, 
    data: currentItem,
    error: itemError,
    replace: replaceItem
  } = useAsyncData<ICDASHIG>(null, dataService.cdashig.getClassesByVersion, [standard, version]);

  // Fetch Sections
  const {
    isLoading: isLoadingSections,
    data: sections,
    error: sectionsError,
    replace: replaceSections
  } = useAsyncData<ISections>(null, documentsService.getDocumentSections, [standard, version, domainId, productSubtype])
 
  // Fetch Implements
  const getItemImplements = async (): Promise<INavItem> => {
    if (currentItem && 'model' in currentItem._links) {
      return await dataService.nav.getNavLinkFromHref(currentItem._links.model.href);
    }
    else return null;
  }
  const { 
    isLoading: isLoadingItemImplements, 
    data: currentItemImplements,
    error: itemImplementsError,
    replace: replaceItemImplements
  } = useAsyncData<INavItem>(null, getItemImplements, [currentItem]);

  // Fetch Class
  const { 
    isLoading: isLoadingClass, 
    data: currentClass,
    error: classError,
    replace: replaceClass
  } = useAsyncData<ICDASHIGClass>(null, dataService.cdashig.getClass, [standard, version, classId]);

  // Lookup Domain
  let currentDomain = null;
  if (!isLoadingClass && currentClass && currentClass.domains && currentClass.domains.length > 0 && domainId) {
    currentDomain = currentClass.domains.find(d => getHrefId(d._links.self) === domainId);

    // Invalid domainId
    if (!currentDomain) setError(`Invalid domainId`, MISSING_RESOURCE_ERROR);
  }

  // Lookup Scenario
  let currentScenario = null;
  if (!isLoadingClass && currentClass && currentClass.scenarios && currentClass.scenarios.length > 0 && scenarioId) {
    currentScenario = currentClass.scenarios.find(s => getHrefId(s._links.self) === scenarioId);

    // Invalid scenarioId
    if (!currentScenario) setError(`Invalid scenarioId`, MISSING_RESOURCE_ERROR);
  }

  // Clear Version and Class if params become undefined/null
  useEffect(() => {
    if (!version) {
      replaceItem(null);
      replaceItemImplements(null);
    }
    if (!classId) replaceClass(null);
  }, [version, classId]);

  // Notify UI of Any Errors
  useEffect(() => {
    const combinedErrors = [itemError, classError, itemImplementsError].filter(e => e).join('; ');
    if (combinedErrors) {
      setError(`Unable to load data for CDASHIG`, combinedErrors);
    }
  }, [itemError, classError, itemImplementsError]);

  return {
    isLoadingItem, isLoadingClass, isLoadingItemImplements, isLoadingSections,
    currentItem, currentClass, currentDomain, currentScenario, currentItemImplements, sections
  };
}
