/**
 * Primary data service which makes each sub-API service available for use throughout the web app. 
 * @packageDocumentation 
 * 
 * @module DataService
 */
import { ADAM } from './api/ADAM';
import { CDASH } from './api/CDASH';
import { CDASHIG } from './api/CDASHIG';
import { CT } from './api/CT';
import { QRS } from './api/QRS';
import { SDTM } from './api/SDTM';
import { SDTMIG } from './api/SDTMIG';
import { Search } from './api/Search';
import { SENDIG } from './api/SENDIG';
import { IGDocuments } from './api/IGDocuments';
import { AuthService } from './AuthService';
import { IntegratedStandards } from './api/IntegratedStandards'
import { NavService } from './NavService';
import { BaseDataService } from './BaseDataService';

export const MISSING_RESOURCE_ERROR = `[MISSING RESOURCE]`;

export enum SUPPORTED_ACCEPT_TYPES {
  JSON = 'application/json', 
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
  CSV = 'text/csv',
  GZ = 'application/gzip'
};

export class DataService {
  private _auth: AuthService;
  private _base: BaseDataService;
  private _nav: NavService;
  private _adam: ADAM;
  private _cdash: CDASH;
  private _cdashig: CDASHIG;
  private _ct: CT;
  private _qrs: QRS;
  private _sdtm: SDTM;
  private _sdtmig: SDTMIG;
  private _search: Search;
  private _sendig: SENDIG;
  private _igdocuments: IGDocuments;
  private _integratedStandards: IntegratedStandards;

  constructor(auth: AuthService) {
    this._auth = auth;
    this._base = new BaseDataService(this._auth);
    this._nav = new NavService(this._auth);
    this._adam = new ADAM(this._auth);
    this._cdash = new CDASH(this._auth);
    this._cdashig = new CDASHIG(this._auth);
    this._ct = new CT(this._auth);
    this._qrs = new QRS(this._auth);
    this._sdtm = new SDTM(this._auth);
    this._sdtmig = new SDTMIG(this._auth);
    this._search = new Search(this._auth);
    this._sendig = new SENDIG(this._auth);
    this._igdocuments = new IGDocuments(this._auth);
    this._integratedStandards = new IntegratedStandards(this._auth);
  }
  
  public get base(): BaseDataService {
    return this._base;
  }  
  public get nav(): NavService {
    return this._nav;
  }  
  public get adam(): ADAM {
    return this._adam;
  }
  public get cdash(): CDASH {
    return this._cdash;
  }
  public get cdashig(): CDASHIG {
    return this._cdashig;
  }
  public get ct(): CT {
    return this._ct;
  }
  public get qrs(): QRS {
    return this._qrs;
  }
  public get sdtm(): SDTM {
    return this._sdtm;
  }
  public get sdtmig(): SDTMIG {
    return this._sdtmig;
  }
  public get search(): Search {
    return this._search;
  }
  public get sendig(): SENDIG {
    return this._sendig;
  }
  public get igDocuments(): IGDocuments {
    return this._igdocuments;
  }
  public get integratedStandards(): IntegratedStandards {
    return this._integratedStandards;
  }
}
