/**
 * Service to handle requesting products and transforming that response into a data model to render the navigation menu.
 * @packageDocumentation
 *
 * @module NavService
 */
import { INavItem } from "../models/INavItem";
import { IProductLink } from "../models/ILink";
import { IProductGroup } from "../models/IProducts";
import { compareDesc } from "date-fns";
import { Products } from "./api/Products";
import { AuthService } from "./AuthService";

/**
 * This also controls the order in the UX
 */
const productGroupKeyMap = {
  "data-collection": "Data Collection",
  "data-tabulation": "Data Tabulation",
  "data-analysis": "Data Analysis",
  integrated: "Integrated",
  qrs: "QRS Instruments",
  terminology: "Terminology",
  "draft-content": "Draft Content",
};

export class NavService {
  private productsService: Products;
  private links: INavItem[] = null;
  private navItemsDict: { [href: string]: INavItem } = null;

  constructor(auth: AuthService) {
    this.productsService = new Products(auth);
  }

  private createNavItemFromProductLink = (pLink: IProductLink): INavItem => {
    const item: INavItem = {
      title: pLink.title || pLink.self.title,
      status: pLink.registrationStatus || "Final",
      shortTitle: pLink.name || pLink.title,
      href: pLink.href,
    };
    if (pLink.standards) {
      item.items = Object.values(pLink.standards).map((standardLink) =>
        this.createNavItemFromProductLink(standardLink)
      );
    }
    return item;
  };

  private zip<T, U>(a: T[], b: U[]): [T, U][] {
    return a.map((x, i) => [x, b[i]]);
  }

  private productLinkFilter(filterKeys: string[], filterValues: string[]) {
    return (pLink: IProductLink): boolean => {
      return this.zip(filterKeys, filterValues).every(
        ([filterKey, filterValue]) => pLink[filterKey] === filterValue
      );
    };
  }

  private getNavItems(
    titles: string[][],
    productGroupLinks: IProductLink[],
    filterKeys: string[],
    filterValues: string[]
  ): INavItem {
    const [currentTitle, ...remainingTitles] = titles;
    return {
      title: currentTitle[0],
      shortTitle: currentTitle[0],
      items:
        remainingTitles.length === 0
          ? productGroupLinks
              .filter(this.productLinkFilter(filterKeys, filterValues))
              .filter(
                (productGroupLink) =>
                  !this.isIntegratedSubDocument(productGroupLink.href)
              )
              .map(this.createNavItemFromProductLink)
          : remainingTitles[0].map((title) =>
              this.getNavItems(
                [[title], ...remainingTitles.slice(1)],
                productGroupLinks,
                filterKeys,
                [...filterValues, title]
              )
            ),
    };
  }

  private async fetchNavLinks(): Promise<INavItem[]> {
    const products = (await this.productsService.getProducts())._links;
    const topLevelNavItems = Object.entries(products)
      .filter(([key]) => key !== "self")
      .map(this.processProductGroup);
    const sortedNavItems = this.sortNavItems(topLevelNavItems);
    return sortedNavItems;
  }

  private processProductGroup = ([productGroupKey, productGroup]: [
    string,
    IProductGroup
  ]): INavItem => {
    const productGroupLinks = this.flattenProductLinks(productGroup);
    if (productGroupKey === "qrs") {
      const instrumentTypes = this.getUniqueInstrumentTypes(productGroupLinks);
      return this.getNavItems(
        [[productGroupKeyMap[productGroupKey]], instrumentTypes],
        productGroupLinks,
        ["instrumentType"],
        []
      );
    }
    return this.getNavItems(
      [[productGroupKeyMap[productGroupKey]]],
      productGroupLinks,
      [],
      []
    );
  };

  private flattenProductLinks(productGroup: IProductGroup): IProductLink[] {
    return Object.keys(productGroup._links)
      .filter((key) => key !== "self")
      .reduce<IProductLink[]>((allLinks, productKey) => {
        const currentLinks = productGroup._links[productKey];
        if (Array.isArray(currentLinks) && currentLinks.length > 0) {
          if (productKey === "packages") {
            allLinks.push(...this.createPackageLinks(currentLinks, productKey));
          } else {
            allLinks.push(
              ...currentLinks.map((link) =>
                this.transformProductLink(link, productKey)
              )
            );
          }
        }
        return allLinks;
      }, [])
      .sort(this.sortProductLinks);
  }

  private createPackageLinks(
    links: IProductLink[],
    productKey: string
  ): IProductLink[] {
    const uniqueDates = this.getUniqueEffectiveDates(links);
    return uniqueDates.map(([effectiveDate, quarter]) => ({
      href: `/mdr/ct/${effectiveDate}`,
      title: `Controlled Terminology Package Effective ${effectiveDate}`,
      name: `CT ${quarter}`,
      type: "Terminology Package",
      effectiveDate,
      product: productKey,
    }));
  }

  private getUniqueEffectiveDates(links: IProductLink[]): [string, string][] {
    return links.reduce<[string, string][]>((uniqueDates, link) => {
      if (!uniqueDates.some(([date]) => date === link.effectiveDate)) {
        uniqueDates.push([link.effectiveDate, link.quarter]);
      }
      return uniqueDates;
    }, []);
  }

  private transformProductLink(
    link: IProductLink,
    productKey: string
  ): IProductLink {
    const transformedLink = { ...link, product: productKey };
    if (this.isQrsInstrument(transformedLink)) {
      transformedLink.instrumentName = transformedLink.href.split("/")[4];
    }
    if (this.isIntegratedStandard(transformedLink)) {
      transformedLink.href = transformedLink.self.href;
    }
    return transformedLink;
  }

  private getUniqueInstrumentTypes(links: IProductLink[]): string[] {
    return links.reduce<string[]>((uniqueTypes, link) => {
      if (!uniqueTypes.includes(link.instrumentType)) {
        uniqueTypes.push(link.instrumentType);
      }
      return uniqueTypes;
    }, []);
  }

  private sortNavItems(navItems: INavItem[]): INavItem[] {
    const sortedNavItems: INavItem[] = [];
    Object.keys(productGroupKeyMap).forEach((groupKey, index) => {
      sortedNavItems[index] = navItems.find(
        (item) => item.title === productGroupKeyMap[groupKey]
      );
    });
    return sortedNavItems.filter((item) => item !== undefined);
  }

  public getNavLinks = async (): Promise<INavItem[]> => {
    if (this.links) return this.links;
    this.links = await this.fetchNavLinks();
    return this.links;
  };

  private flattenNavLinks(
    flatObj: { [href: string]: INavItem },
    topNavItem: INavItem
  ): { [href: string]: INavItem } {
    if (topNavItem?.items) {
      for (const navItem of topNavItem.items) {
        if ("href" in navItem) {
          flatObj[navItem.href.toLowerCase()] = navItem;
        }
        this.flattenNavLinks(flatObj, navItem);
      }
    }
    return flatObj;
  }

  public async getNavLinkFromHref(pageHref: string): Promise<INavItem> {
    if (!this.navItemsDict) {
      const navLinks = await this.getNavLinks();
      this.navItemsDict = navLinks.reduce(this.flattenNavLinks.bind(this), {});
    }
    return this.navItemsDict[pageHref.toLowerCase()];
  }

  private isQrsInstrument(link: IProductLink): boolean {
    return "instrumentType" in link;
  }

  private isIntegratedStandard(link: IProductLink): boolean {
    return link.self?.type === "Integrated Standard";
  }

  private isIntegratedSubDocument(link: string): boolean {
    return link.includes("integrated") && link.split("/").length > 5;
  }

  private sortProductLinks(linkA: IProductLink, linkB: IProductLink): number {
    // Sort by Product Type, Instrument Type (QRS only), Instrument Name (QRS Only), Effective Date desc, Product Name
    // For example:
    // (CDASH/CDASHIG...), (Clinical Classification/Functional Test...), (AIMS01/ECOG1), (2019-09-17/2018-11-20), (ADaM OCCDS v1.0/ADaMIG v1.1)
    if (linkA.product !== linkB.product)
      return linkA.product < linkB.product ? -1 : 1;
    if (linkA.instrumentType !== linkB.instrumentType)
      return linkA.instrumentType < linkB.instrumentType ? -1 : 1;
    if (linkA.instrumentName !== linkB.instrumentName)
      return linkA.instrumentName < linkB.instrumentName ? -1 : 1;
    if (linkA.effectiveDate !== linkB.effectiveDate)
      return compareDesc(
        new Date(linkA.effectiveDate),
        new Date(linkB.effectiveDate)
      );
    return linkA.name < linkB.name ? -1 : 1;
  }
}
