import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../components/AppContext/AppContext";
import { getHrefId } from "../../../utils/LinkUtils";
import { ICDASHIG, ICDASHIGClass } from "../../../models/ICDASHIG";

export default function useCDASHIGRouteObserver(
  standard: string, version: string, classId: string, domainId: string, scenarioId: string, currentItem: ICDASHIG, currentClass: ICDASHIGClass,
  getLink: (standard: string, version: string, classId?: string, domainId?: string, scenarioId?: string, keepCurrentParams?: boolean) => string
) {
  const history = useHistory();
  const { setError } = useContext(AppContext);

  const getDefaultLink = (): string | undefined => {
    let link: string = undefined;
  
    // do we have an item with classes, but don't have either a classId or domainId in the url?
    if (currentItem && currentItem.classes && (!classId || !domainId || !scenarioId)) {
  
      // no classId and no domainId and no scenarioId
      if (!classId && !domainId && !scenarioId) {
        // we can only select a default class from the root CDASHIG data, 
        // when we have a classId, then we can handle selecting a domain and scenario if applicable
        const newClassId = getHrefId(currentItem.classes[0]._links.self);
        link = getLink(standard, version, newClassId, undefined, undefined, true);
      }
  
      // do we have a classId and selected class instance?
      else if (classId && currentClass && getHrefId(currentClass._links.self) === classId) {

        // no domainId and no scnearioId
        if (!domainId && !scenarioId) {
          const newDomain = currentClass.domains && currentClass.domains.length ? currentClass.domains[0] : null;
          const newDomainId =  newDomain ? getHrefId(newDomain._links.self) : '';
          const newScenario = newDomain && currentClass.scenarios && currentClass.scenarios.length > 0 
                                ? currentClass.scenarios.find(s => getHrefId(s._links.parentDomain) === newDomainId) : null;
          const newScenarioId = newScenario ? getHrefId(newScenario._links.self) : '';
          
          // does selected class have a domain? does that domain have a scenario?
          if (newDomainId) {
            link = getLink(standard, version, classId, newDomainId, newScenarioId, true);
          }
        }
    
        // have domainId but no scnearioId
        else if (domainId && !scenarioId) {
          const newScenario = currentClass.scenarios && currentClass.scenarios.length > 0 
                                ? currentClass.scenarios.find(s => getHrefId(s._links.parentDomain) === domainId) : null;
          const newScenarioId = newScenario ? getHrefId(newScenario._links.self) : '';
    
          //does selected domain have a scenario?
          if (newScenarioId) {
            link = getLink(standard, version, classId, domainId, newScenarioId, true);
          }
        }
      }
      // have domainId but no classId
      else if (domainId && !classId && currentItem.classes) {
        let newClass = null;
        for (let index = 0; index < currentItem.classes.length; index++) {
          const _class = currentItem.classes[index];
          const foundParentClass = _class.domains && _class.domains.some(d => getHrefId(d._links.self) === domainId);
          if (foundParentClass) {
            newClass = _class;
            break;
          }
        }
        const newClassId = newClass ? getHrefId(newClass._links.self) : null;

        if (newClassId) {
          link = getLink(standard, version, newClassId, domainId, "", true);
        }
      }
      // have scenarioId but no domainId and no classId
      else if (scenarioId && !domainId && !classId && currentItem.classes) {
        let newClassId = null, newDomainId = null;
        for (let index = 0; index < currentItem.classes.length; index++) {
          const _class = currentItem.classes[index];
          if (!_class.domains) continue;
          for (let index = 0; index < _class.domains.length; index++) {
            const _domain = _class.domains[index];
            const foundParentDomain = _domain._links.scenarios && _domain._links.scenarios.some(sl => getHrefId(sl) === scenarioId);
            
            if (foundParentDomain) {
              newClassId = getHrefId(_class._links.self);
              newDomainId = getHrefId(_domain._links.self);
              break;
            }
          }
        }
        if (newClassId && newDomainId) {
          link = getLink(standard, version, newClassId, newDomainId, scenarioId, true);
        }
      }
    }
  
    return link;
  }

  // Watch Params, Update URL if needed
  useEffect(() => {
    if (currentItem) {
      try {
        const defaultLink = getDefaultLink();
        
        if (defaultLink) {
          history.replace(defaultLink);
        }
      }
      catch (error) {
        setError(`Unable to update CDASHIG route`, error);
      }
    }
  }, [version, classId, domainId, scenarioId, currentItem, currentClass]);
}
