/**
 * Data service for ADAM requests.
 * @packageDocumentation
 *
 * @module DataService-ADAM
 */
import {
  IADAM,
  IADAMDataStructure,
  IADAMVariableSet,
} from "../../models/IADAM";
import { BaseDataService } from "../BaseDataService";

export class ADAM extends BaseDataService {
  public getDataStructuresByVersion = async (
    standard: string,
    version: string
  ): Promise<IADAM> => {
    if (!version) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/adam` : `adam/${version}`
    }/datastructures?expand=true`;
    const result = await this.get<IADAM>(url);
    return result;
  };

  public getDataStructure = async (
    standard: string,
    version: string,
    dataStructureId: string
  ): Promise<IADAMDataStructure> => {
    if (!version || !dataStructureId) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/adam` : `adam/${version}`
    }/datastructures/${dataStructureId}`;
    let result = await this.get<IADAMDataStructure>(url);
    return result;
  };

  public getVariableSet = async (
    standard: string,
    version: string,
    dataStructureId: string,
    variableSetId: string
  ): Promise<IADAMVariableSet> => {
    if (!version || !dataStructureId || !variableSetId) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/adam` : `adam/${version}`
    }/datastructures/${dataStructureId}/varsets/${variableSetId}`;
    let result = await this.get<IADAMVariableSet>(url);
    return result;
  };
}
