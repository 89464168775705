import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../components/AppContext/AppContext";
import { getHrefId } from "../../../utils/LinkUtils";
import { IUseCases, IUseCaseSections } from "../../../models/IDocuments";

export default function useAllExamplesRouteObserver(
  standard: string, version: string, useCaseId: string, isLoadingUseCase: boolean, isLoadingUseCaseSections: boolean, useCaseData: IUseCases, useCaseSections: IUseCaseSections[], subStandard: string, documentSection: string,
  getLink: (standard: string, version: string, useCase: string, subStandard?: string, documentSection?: string ) => string
) {
  const history = useHistory();
  const { setError } = useContext(AppContext);

  const getDefaultLink = (): string | undefined => {
    let link: string = undefined;
    if (!useCaseId && useCaseData && !isLoadingUseCase) {
        const currentUseCaseId = getHrefId(useCaseData.useCases[0]._links.self);
        link = getLink(standard, version, currentUseCaseId);
    }

    if (useCaseId && !isLoadingUseCaseSections && !documentSection && useCaseSections && useCaseSections.length > 0) {
      const subDocument = useCaseSections[0]
      if (subDocument.sections) {
        const section = subDocument.sections[0]
        link = getLink(standard, version, useCaseId, subDocument.standardSubtype, section.name);
      }
    }
  
    return link;
  }

  // Watch Params, Update URL if needed
  useEffect(() => {
    if (!(isLoadingUseCase || isLoadingUseCaseSections)) {
      try {
        const defaultLink = getDefaultLink();
        
        if (defaultLink) {
          history.replace(defaultLink);
        }
      }
      catch (error) {
        setError(`Unable to update ExamplesRoute route`, error);
      }
    }
  }, [standard, version, useCaseId, isLoadingUseCase, isLoadingUseCaseSections, useCaseData, useCaseSections, subStandard, documentSection]);
}
