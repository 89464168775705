import React, { useState } from 'react';
import { SEARCH_RESULTS_PAGE_SIZE } from '../../config';
import { ISearchParams } from '../../models/ISearch';
import { AuthService } from '../../services/AuthService';
import { DataService } from '../../services/DataService';
import AppContext, { IAppError } from './AppContext';

const AppContextProvider: React.FunctionComponent = ({ children }) => {
  const [ authService ] = useState<AuthService>(() => new AuthService());
  const [ dataService ] = useState<DataService>(() => new DataService(authService));
  const [ appError, setAppError ] = useState<IAppError>();
  const [ searchParams, setSearchParams ] = useState<ISearchParams>();

  const clearError = () => appError ? setAppError(null) : undefined;

  const setError = (title: string, message: string, isUncaught: boolean = false) => {    
    if (!appError || (appError && (title !== appError.title || message !== appError.message))) {
      setAppError({ 
        title, message, isUncaught
      });
    }
  }

  const makeDefaultSearchParams = (): ISearchParams => {
    return {
      text: "", 
      visible: false,
      pageSize: SEARCH_RESULTS_PAGE_SIZE,
      start: 0,
      includeFacets: true,
      activeFacets: {}
    };
  }

  const search = (newSearchParams: ISearchParams) => {
    setSearchParams({
      ...makeDefaultSearchParams(),
      ...newSearchParams
    });
  }

  const closeSearch = () => {
    setSearchParams(makeDefaultSearchParams());
  }

  const appContext = {
    authService, dataService, appError, searchParams, clearError, setError, search, closeSearch
  };

  return (
    <AppContext.Provider value={appContext}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider;