import React, { useEffect, useState } from 'react';
import List, { IItem } from '../../../components/List/List';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { ICDASHIGField } from '../../../models/ICDASHIG';
import { onRenderMappingTargetsLink } from './Fields';
import { Link } from 'react-router-dom';
import { ILink } from '../../../models/ILink';
import { onRenderCodelistLinks, parseCodelistLinks } from '../../../components/Link/Link';

export interface IFieldsIGProps {
  fields: ICDASHIGField[];
  isLoading: boolean;
  isDomain?: boolean;
}

export interface ICDASHIGFieldVM extends ICDASHIGField {
  mappingTargetsLink: string;
  codelistLinks: string;
  implementsLink: string;
}

const FieldsIG: React.FunctionComponent<IFieldsIGProps> = ({ fields, isLoading, isDomain = false }) => {
  const [ items, setItems ] = useState<any[]>([]);

  const onRenderImplementsLink = (item?: ICDASHIGFieldVM, index?: number, column?: IColumn) => {
    if (item && item.implementsLink) {      
      const implementsLink: ILink = JSON.parse(item.implementsLink);
      const implementsFieldId = implementsLink.href.substring(implementsLink.href.lastIndexOf('/') + 1);
      const linkPath = implementsLink.href.replace('/fields/', '?filterFields=name&filterIsExact=true&filter=');
      return (
        <Link key={implementsFieldId} to={linkPath}>{implementsFieldId}</Link>
      ) 
    }
  }
  
  let columns: IColumn[] = [
    { key: "ordinal", fieldName: "ordinal", name: "Ordinal", minWidth: 70, maxWidth: 70, isResizable: true, isSorted: true, isSortedDescending: false, isMultiline: true },
    { key: "name", fieldName: "name", name: "Name", minWidth: 70, maxWidth: 100, isResizable: true, isMultiline: true },
    { key: "label", fieldName: "label", name: "Label", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "definition", fieldName: "definition", name: "Definition", minWidth: 100, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "questionText", fieldName: "questionText", name: "Question Text", minWidth: 100, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "prompt", fieldName: "prompt", name: "Prompt", minWidth: 100, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "simpleDatatype", fieldName: "simpleDatatype", name: "Data Type", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "core", fieldName: "core", name: "Core", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "completionInstructions", fieldName: "completionInstructions", name: "Completion Instructions", minWidth: 200, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "implementationNotes", fieldName: "implementationNotes", name: "Implementation Notes", minWidth: 200, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "mappingInstructions", fieldName: "mappingInstructions", name: "Mapping Instructions", minWidth: 200, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "mappingTargetsLink", fieldName: "mappingTargetsLink", name: "Mapping Targets", minWidth: 120, maxWidth: 200, isResizable: true, isMultiline: true, onRender: onRenderMappingTargetsLink },
    { key: "codelistLink", fieldName: "codelistLink", name: "Code List", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true, onRender: onRenderCodelistLinks },
  ];

  if (isDomain) {
    const implementsLinkColumn = { 
      key: "implementsLink", fieldName: "implementsLink", name: "Implements", minWidth: 80, maxWidth: 200, isResizable: true, isMultiline: true, onRender: onRenderImplementsLink 
    };
    //Insert "Implements" as the right-most column when rendering Domain fields
    columns.push(implementsLinkColumn);
  }

  const mapItemsWithDefaults = (flds: ICDASHIGField[]): IItem[] => {
    return flds.map<IItem>(f => {
      const codelistLinks = parseCodelistLinks(f._links.codelist);
      const mappingTargetsLink = f._links.sdtmigDatasetMappingTargets
        ? JSON.stringify(
            f._links.sdtmigDatasetMappingTargets.map((mtl) => mtl.href)
          )
        : f._links.mappingTargets
        ? JSON.stringify(
            f._links.mappingTargets.map((mtl) => mtl.href)
          )
        : "";
      const implementsLink = f._links.implements ? JSON.stringify(f._links.implements) : '';

      return {
        key: f.name,
        ordinal: f.ordinal ? parseInt(f.ordinal) : 0,
        name: f.name,
        label: f.label,
        definition: f.definition,
        questionText: f.questionText,
        prompt: f.prompt,
        simpleDatatype: f.simpleDatatype,
        core: f.core,
        completionInstructions: f.completionInstructions,
        implementationNotes: f.implementationNotes,
        mappingInstructions: f.mappingInstructions,
        mappingTargetsLink,
        codelistLinks,
        implementsLink,
      };
    })
  }

  useEffect(() => {
    if (fields) {
      setItems(mapItemsWithDefaults(fields));
    }
  }, [fields]);

  return (
    <List 
      items={items}
      columns={columns} 
      isLoading={isLoading} 
    />
  );
}

export default FieldsIG;
