/**
 * Module to wrap configuration received from environment variables at build time. Responsible for setting default values when configuration keys are not explicitly set.
 * @packageDocumentation 
 * 
 * @module Config
 */
import { parseBoolString } from "./utils/DataUtils";

// Create React App Environment Variables
// https://create-react-app.dev/docs/adding-custom-environment-variables

/**
 * B2C Authentication Configuration
 */
export const AUTHENTICATION = {
  tenant: process.env.REACT_APP_B2C_TENANT_NAME,
  clientId: process.env.REACT_APP_B2C_CLIENT_ID,
  b2cPolicies: {
    names: {
      signUpSignIn: process.env.REACT_APP_B2C_FLOW_SIGN_IN_NAME,
      forgotPassword: process.env.REACT_APP_B2C_FLOW_PASSWORD_RESET_NAME,
      editProfile: process.env.REACT_APP_B2C_FLOW_EDIT_PROFILE_NAME,
    },
    authorities: {
      signUpSignIn: {
        authority: process.env.REACT_APP_B2C_FLOW_SIGN_IN_ENDPOINT
      },
      forgotPassword: {
        authority: process.env.REACT_APP_B2C_FLOW_PASSWORD_RESET_ENDPOINT
      },
      editProfile: {
        authority: process.env.REACT_APP_B2C_FLOW_EDIT_PROFILE_ENDPOINT
      },
    }
  }
}

/**
 * Base path of the SPA.
 */
export const PUBLIC_URL = process.env.PUBLIC_URL;

/**
 * Define the base API URL for which API requests should be sent.
 */
export const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Define the Azure API Management (APIM) Developer portal URL that will be displayed on the dashboard page and user settings gear.
 * 
 * Default Value: `''`
 */
export const DEV_PORTAL_URL = process.env.REACT_APP_DEV_PORTAL_URL || '';

/**
 * Define the Azure Application Insights instrumentation key for logging.
 * 
 * Default Value: `null`
 */
export const APP_INSIGHTS_KEY = process.env.REACT_APP_APP_INSIGHTS_KEY || null;

/**
 * Define the B2C token scopes that will be requested when a B2C access token is acquired for calling the API.
 * 
 * Default Value: `[]`
 */
export const B2C_TOKEN_SCOPES =  typeof(process.env.REACT_APP_B2C_API_TOKEN_SCOPES) === "undefined" ? [] : process.env.REACT_APP_B2C_API_TOKEN_SCOPES.split(',');

/** 
 * Define the number of search results displayed when searching before pagination becomes available.
 * 
 * Default Value: `10`
 */
export const SEARCH_RESULTS_PAGE_SIZE = typeof(process.env.REACT_APP_SEARCH_RESULTS_PAGE_SIZE) === "undefined" ? 10 : Number.parseInt(process.env.REACT_APP_SEARCH_RESULTS_PAGE_SIZE);

/**
 * Enable client-side browser cache using IndexedDB (via LocalForage) for API requests.
 * 
 * Default Value: `true`
 */
export const CACHE_ENABLED = typeof(process.env.REACT_APP_CACHE_ENABLED) === "undefined" ? true : parseBoolString(process.env.REACT_APP_CACHE_ENABLED);

/**
 * Defines how long the client-side browser cache remains valid before data is requested from the server.
 * 
 * Default Value: `3600`
 */
export const CACHE_TTL_SECONDS = typeof(process.env.REACT_APP_CACHE_TTL_SECONDS) === "undefined" ? 3600 : Number.parseInt(process.env.REACT_APP_CACHE_TTL_SECONDS);

/**
 * Require the user to be authenticated when accessing the SPA. If enabled, users will be redirected to the B2C login page upon accessing the SPA.
 * 
 * Default Value: `true`
 */
export const FORCE_LOGIN = typeof(process.env.REACT_APP_FORCE_LOGIN) === "undefined" ? true : parseBoolString(process.env.REACT_APP_FORCE_LOGIN);

/**
 * Enable debug trace messages that will be sent to Azure Application Insights.
 * 
 * Default Value: `false`
 */
export const DEBUG_LOGGING_ENABLED = typeof(process.env.REACT_APP_DEBUG_LOGGING_ENABLED) === "undefined" ? false : parseBoolString(process.env.REACT_APP_DEBUG_LOGGING_ENABLED);