/**
 * Core routing setup using React Router. Responsible for routing requests to the dashboard, individual products including nested entities, and the 'Not Found' screen.
 * @packageDocumentation 
 * 
 * @module RouteManager
 */
import React, { useContext } from 'react';
import { Switch, Route, useHistory } from "react-router-dom";

import AppContext from '../components/AppContext/AppContext';
import MissingResource from '../components/MissingResource/MissingResource';
import Dashboard from './Dashboard/Dashboard';
import CDASHIGComponent from './Collection/CDASHIG';
import CDASHComponent from './Collection/CDASH';
import SDTMIGComponent from './Tabulation/SDTMIG';
import SDTMComponent from './Tabulation/SDTM';
import SENDIGComponent from './Tabulation/SENDIG';
import ADAMComponent from './Analysis/ADAM';
import QRSComponent from './QRS/QRS';
import CTComponent from './Terminology/CT';
import IntegratedStandardComponent, { getIntegratedADaMLink, getIntegratedCDASHLink, getIntegratedSDTMLink, getIntegratedSENDLink } from './Integrated/Integrated';

const RouteManager: React.FunctionComponent = () => {
  const { appError, clearError} = useContext(AppContext);
  const history = useHistory();

  history.listen(clearError);

  const safeRouteHandler = (component: React.FunctionComponent): React.FunctionComponent => {
    return appError && appError.isUncaught ? null : component;
  }

  return (
    <Switch>
      {/* DASHBOARD */}
      <Route exact path="/" component={safeRouteHandler(Dashboard)} />

      {/* CDASH */}
      <Route exact path={[
        `/mdr/:standard(cdash)/:version`,
        `/mdr/:standard(cdash)/:version/classes/:classId`,
        `/mdr/:standard(cdash)/:version/domains/:domainId`,
        `/mdr/:standard(cdash)/:version/classes/:classId/domains/:domainId`,
        `/mdr/:standard(cdash)/:version/domains/:domainId/classes/:classId`,
      ]} component={safeRouteHandler(CDASHComponent)} />

      {/* CDASHIG */}
      <Route exact path={[
        `/mdr/:standard(cdashig)/:version`,
        `/mdr/:standard(cdashig)/:version/classes/:classId`,
        `/mdr/:standard(cdashig)/:version/classes/:classId/domains/:domainId`,
        `/mdr/:standard(cdashig)/:version/classes/:classId/domains/:domainId/scenarios/:scenarioId`,
        `/mdr/:standard(cdashig)/:version/domains/:domainId`,
        `/mdr/:standard(cdashig)/:version/scenarios/:scenarioId`,
      ]} component={safeRouteHandler(CDASHIGComponent)} />

      {/* SDTM */}
      <Route exact path={[
        `/mdr/:standard(sdtm)/:version`,
        `/mdr/:standard(sdtm)/:version/classes/:classId`,
        `/mdr/:standard(sdtm)/:version/datasets/:datasetId`,
        `/mdr/:standard(sdtm)/:version/classes/:classId/datasets/:datasetId`,
        `/mdr/:standard(sdtm)/:version/datasets/:datasetId/classes/:classId`
      ]} component={safeRouteHandler(SDTMComponent)} />

      {/* SDTMIG */}
      <Route exact path={[
        `/mdr/:standard(sdtmig)/:version`,
        `/mdr/:standard(sdtmig)/:version/classes/:classId`,
        `/mdr/:standard(sdtmig)/:version/datasets/:datasetId`,
        `/mdr/:standard(sdtmig)/:version/classes/:classId/datasets/:datasetId`,
        `/mdr/:standard(sdtmig)/:version/datasets/:datasetId/classes/:classId`
      ]} component={safeRouteHandler(SDTMIGComponent)} />

      {/* SENDIG */}
      <Route exact path={[
        `/mdr/:standard(sendig)/:version`,
        `/mdr/:standard(sendig)/:version/classes/:classId`,
        `/mdr/:standard(sendig)/:version/datasets/:datasetId`,
        `/mdr/:standard(sendig)/:version/classes/:classId/datasets/:datasetId`,
        `/mdr/:standard(sendig)/:version/datasets/:datasetId/classes/:classId`
      ]} component={safeRouteHandler(SENDIGComponent)} />

      {/* ADAM */}
      <Route exact path={[
        `/mdr/:standard(adam)/:version`,
        `/mdr/:standard(adam)/:version/datastructures/:dataStructureId`,
        `/mdr/:standard(adam)/:version/variablesets/:variableSetId`,
        `/mdr/:standard(adam)/:version/datastructures/:dataStructureId/variablesets/:variableSetId`,
        `/mdr/:standard(adam)/:version/variablesets/:variableSetId/datastructures/:dataStructureId`
      ]} component={safeRouteHandler(ADAMComponent)} />

      {/* QRS */}
      <Route exact path={[
        `/mdr/qrs/instruments/:instrumentId/versions/:version`,
        `/mdr/qrs/instruments/:instrumentId/versions/:version/items/:itemId`,
      ]} component={safeRouteHandler(QRSComponent)} />

      {/* CT */}
      <Route exact path={[
        `/mdr/ct/:version`,
        `/mdr/ct/:version/packages/:packageId`,
        `/mdr/ct/:version/packages/:packageId/codelists/:codelistId`,
        `/mdr/ct/:version/packages/:packageId/codelists/:codelistId/terms/:termId`,
      ]} component={safeRouteHandler(CTComponent)} />
	
      {/* Integrated Standards */}
      <Route exact path={[
        `/mdr/integrated/:standard/:version`,
        `/mdr/integrated/:standard/:version/:productSubtype`,
        `/mdr/integrated/:standard/:version/:productSubtype/classes/:classId`,
        `/mdr/integrated/:standard/:version/:productSubtype/classes/:classId/domains/:domainId`,
        `/mdr/integrated/:standard/:version/:productSubtype/classes/:classId/domains/:domainId/scenarios/:scenarioId`,
        `/mdr/integrated/:standard/:version/:productSubtype/domains/:domainId`,
        `/mdr/integrated/:standard/:version/:productSubtype/scenarios/:scenarioId`,
        `/mdr/integrated/:standard/:version/:productSubtype/classes/:classId/datasets/:datasetId`,
        `/mdr/integrated/:standard/:version/:productSubtype/datasets/:datasetId/classes/:classId`,
        `/mdr/integrated/:standard/:version/:productSubtype/datastructures/:dataStructureId`,
        `/mdr/integrated/:standard/:version/:productSubtype/variablesets/:variableSetId`,
        `/mdr/integrated/:standard/:version/:productSubtype/variablesets/:variableSetId/datastructures/:dataStructureId`,
        `/mdr/integrated/:standard/:version/:productSubtype/datastructures/:dataStructureId/variablesets/:variableSetId`,
        `/mdr/integrated/:standard/:version/:productSubtype`,
        `/mdr/integrated/:standard/:version/:productSubtype/:useCaseId`,
        `/mdr/integrated/:standard/:version/:productSubtype/:useCaseId/:subStandard/:documentSection`
      ]} component={safeRouteHandler(IntegratedStandardComponent)} />

      {/* Integrated CDASH */}
      <Route exact path={[
        `/mdr/integrated/:standard/:version/:productSubtype(cdash)`,
        `/mdr/integrated/:standard/:version/:productSubtype(cdash)/classes/:classId`,
        `/mdr/integrated/:standard/:version/:productSubtype(cdash)/classes/:classId/domains/:domainId`,
        `/mdr/integrated/:standard/:version/:productSubtype(cdash)/classes/:classId/domains/:domainId/scenarios/:scenarioId`,
        `/mdr/integrated/:standard/:version/:productSubtype(cdash)/domains/:domainId`,
        `/mdr/integrated/:standard/:version/:productSubtype(cdash)/scenarios/:scenarioId`,
      ]} render={(props) => <CDASHIGComponent getLinkOverride={getIntegratedCDASHLink}/>} />

      	{/* Integrated SEND */}
      	<Route exact path={[
        `/mdr/integrated/:standard/:version/:productSubtype(send)`,
        `/mdr/integrated/:standard/:version/:productSubtype(send)/classes/:classId`,
        `/mdr/integrated/:standard/:version/:productSubtype(send)/datasets/:datasetId`,
        `/mdr/integrated/:standard/:version/:productSubtype(send)/classes/:classId/datasets/:datasetId`,
        `/mdr/integrated/:standard/:version/:productSubtype(send)/datasets/:datasetId/classes/:classId`
      	]} render={(props) => <SENDIGComponent getLinkOverride={getIntegratedSENDLink}/>} />
      	
	{/* Integrated SDTM */}
      	<Route exact path={[
        `/mdr/integrated/:standard/:version/:productSubtype(sdtm)`,
        `/mdr/integrated/:standard/:version/:productSubtype(sdtm)/classes/:classId`,
        `/mdr/integrated/:standard/:version/:productSubtype(sdtm)/datasets/:datasetId`,
        `/mdr/integrated/:standard/:version/:productSubtype(sdtm)/classes/:classId/datasets/:datasetId`,
        `/mdr/integrated/:standard/:version/:productSubtype(sdtm)/datasets/:datasetId/classes/:classId`
      	]} render={(props) => <SDTMIGComponent getLinkOverride={getIntegratedSDTMLink}/>} />
	
	{/* Integrated ADAM */}
      	<Route exact path={[
        `/mdr/integrated/:standard/:version/:productSubtype(adam)`,
        `/mdr/integrated/:standard/:version/:productSubtype(adam)/datastructures/:dataStructureId`,
        `/mdr/integrated/:standard/:version/:productSubtype(adam)/variablesets/:variableSetId`,
        `/mdr/integrated/:standard/:version/:productSubtype(adam)/datastructures/:dataStructureId/variablesets/:variableSetId`,
        `/mdr/integrated/:standard/:version/:productSubtype(adam)/variablesets/:variableSetId/datastructures/:dataStructureId`]} render={(props) => <ADAMComponent getLinkOverride={getIntegratedADaMLink}/>} />

      {/* CATCH ALL / NOT FOUND / MISSING RESOURCE */}
      <Route component={safeRouteHandler(MissingResource)} />

    </Switch>
  )
}

export default RouteManager
