/**
 * Data service for Document requests.
 * @packageDocumentation
 *
 * @module DataService-IGDocuments
 */
import {
  ISections,
  ISectionDocumentsResponse,
  IUseCases,
  IUseCaseSectionsResponse,
  IUseCaseSections,
} from "../../models/IDocuments";
import { BaseDataService } from "../BaseDataService";

export class IGDocuments extends BaseDataService {
  public getDocumentSections = async (
    documentType: string,
    version: string,
    structure: string,
    standardSubtype?: string
  ): Promise<ISections> => {
    if (!version) return null;
    let result = await this.get<ISections>(
      `/api/mdr/documents${
        standardSubtype ? `/integrated` : ``
      }/${documentType}/${version}${
        standardSubtype ? `/${standardSubtype}` : ``
      }/${structure}/sections`
    );
    return result;
  };

  public getDocumentsForSection = async (
    documentType: string,
    version: string,
    section: string,
    documentStructure?: string,
    useCase?: string,
    standardSubtype?: string
  ): Promise<ISectionDocumentsResponse> => {
    var result;
    if (useCase) {
      result = await this.get<ISectionDocumentsResponse>(
        `/api/mdr/documents${
          standardSubtype ? `/integrated` : ``
        }/${documentType}/${version}${
          standardSubtype ? `/${standardSubtype}` : ``
        }/usecases/${useCase}/sections/${section.toLowerCase()}`
      );
      return result;
    } else {
      result = await this.get<ISectionDocumentsResponse>(
        `/api/mdr/documents${
          standardSubtype ? `/integrated` : ``
        }/${documentType}/${version}${
          standardSubtype ? `/${standardSubtype}` : ``
        }/${documentStructure}/sections/${section.toLowerCase()}`
      );
      return result;
    }
  };

  public getUseCasesForStandard = async (
    documentType: string,
    version: string,
    standardSubtype?: string
  ): Promise<IUseCases> => {
    let result = await this.get<IUseCases>(
      `/api/mdr/documents${
        standardSubtype ? `/integrated` : ``
      }/${documentType}/${version}${
        standardSubtype ? `/${standardSubtype}` : ``
      }/usecases`
    );
    return result;
  };

  public getSectionsForUseCase = async (
    documentType: string,
    version: string,
    usecase: string,
    standardSubtype?: string
  ): Promise<IUseCaseSections[]> => {
    let result = await this.get<IUseCaseSectionsResponse>(
      `/api/mdr/documents${
        standardSubtype ? `/integrated` : ``
      }/${documentType}/${version}${
        standardSubtype ? `/${standardSubtype}` : ``
      }/usecases/${usecase}/sections`
    );
    return result.useCaseSections;
  };
}
