/**
 * Data service for SENDIG requests.
 * @packageDocumentation
 *
 * @module DataService-SENDIG
 */
import { ISENDIG, ISENDIGClass, ISENDIGDataset } from "../../models/ISENDIG";
import { BaseDataService } from "../BaseDataService";

export class SENDIG extends BaseDataService {
  public getClassesByVersion = async (
    standard: string,
    version: string
  ): Promise<ISENDIG> => {
    if (!version) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/send` : `sendig/${version}`
    }/classes?expand=true`;
    const result = await this.get<ISENDIG>(url);
    return result;
  };

  public getDatasetsByVersion = async (
    standard: string,
    version: string
  ): Promise<ISENDIG> => {
    if (!version) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/send` : `sendig/${version}`
    }/datasets?expand=true`;
    const result = await this.get<ISENDIG>(url);
    return result;
  };

  public getClass = async (
    standard: string,
    version: string,
    classId: string
  ): Promise<ISENDIGClass> => {
    if (!version || !classId) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/send` : `sendig/${version}`
    }/classes/${classId}`;
    const result = await this.get<ISENDIGClass>(url);
    return result;
  };

  public getDataset = async (
    standard: string,
    version: string,
    datasetId: string
  ): Promise<ISENDIGDataset> => {
    if (!version || !datasetId) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/send` : `sendig/${version}`
    }/datasets/${datasetId}`;
    const result = await this.get<ISENDIGDataset>(url);
    return result;
  };
}
