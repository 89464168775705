import React from 'react';
import Section from '../../../components/Section/Section';
import { ISDTMIGClass, ISDTMIGDataset } from '../../../models/ISDTMIG';
import { Stack } from '@fluentui/react/lib/Stack';
import { Label } from '@fluentui/react/lib/Label';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import { ISDTMDataset, ISDTMClass } from '../../../models/ISDTM';
import { PivotKey } from '../../../models/PivotKey';
import { ISections } from '../../../models/IDocuments';
import { capitalizeFirst } from '../../../utils/DataUtils';
import ImplementationGuideDocument from '../../../components/ImplementationGuideDocument/ImplementationGuideDocument';

export interface IClassDatasetTabsProps {
  isLoadingClass: boolean;
  isLoadingDataset: boolean;
  currentClass: ISDTMClass | ISDTMIGClass;
  currentDataset: ISDTMDataset | ISDTMIGDataset;
  selectedPivotKey: PivotKey;
  setSelectedPivotKey: (pivotKey: PivotKey) => void;
  isLoadingSections: boolean;
  sections: ISections;
  standardType: string;
  version: string;
  standardSubtype?: string;
}

const ClassDatasetTabs: React.FunctionComponent<IClassDatasetTabsProps> = ({ 
  isLoadingClass, isLoadingDataset, currentClass, currentDataset, selectedPivotKey, setSelectedPivotKey, isLoadingSections, sections,
  standardType, version, standardSubtype
}) => {

  return (
    <Section>
      {!isLoadingClass && currentClass && (!currentClass.datasets || (!isLoadingDataset && currentDataset))
        ? <>
            <Pivot selectedKey={selectedPivotKey} onLinkClick={(pivotItem: PivotItem) => setSelectedPivotKey(pivotItem.props.itemKey as PivotKey)}>
              <PivotItem itemKey={PivotKey.Class} headerText={currentClass.name}>
                <Stack tokens={{ childrenGap: 20 }}>
                  <Stack>
                    <Label>Name</Label>
                    <span>{currentClass.label}</span>
                  </Stack>
                  <Stack>
                    <Label>Description</Label>
                    <span>{currentClass.description}</span>
                  </Stack>
                </Stack>
              </PivotItem>
              
              {!isLoadingDataset && currentDataset && (
                <PivotItem itemKey={PivotKey.Dataset} headerText={currentDataset.name}>
                  <Stack tokens={{ childrenGap: 20 }}>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <Stack>
                        <Label>Name</Label>
                        <span>{currentDataset.label}</span>
                      </Stack>
                      {currentDataset.datasetStructure && (
                        <Stack>
                          <Label>Structure</Label>
                          <span>{currentDataset.datasetStructure}</span>
                        </Stack>
                      )}
                      {/* <Stack>
                        <Label>Dataset Version</Label>
                        <span>???</span>
                      </Stack> */}
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }} maxWidth={"50%"}>
                      {currentDataset.description && (
                        <Stack>
                          <Label>Description</Label>
                          <span>{currentDataset.description}</span>
                        </Stack>
                      )}
                      <Stack>
                        <Label>Status</Label>
                        <span>{currentDataset.status || "Final"}</span>
                      </Stack>
                    </Stack>
                  </Stack>
                </PivotItem>
              )}
              {!isLoadingSections && sections && !isLoadingDataset && currentDataset && (
                    sections.sections.filter(sectionName => sectionName !== "publication").map(sectionName => (
                    <PivotItem itemKey={sectionName} headerText={capitalizeFirst(sectionName)}>
                          <ImplementationGuideDocument 
                            sectionName={sectionName}
                            standardType={standardType}
                            version={version}
                            standardSubtype={standardSubtype}
                            documentStructure={currentDataset.name}>
                          </ImplementationGuideDocument>
                      
                    </PivotItem>
                    )))}
            </Pivot>
          </>
        : <Shimmer />
      }          
    </Section>
  );
}

export default ClassDatasetTabs;
