/**
 * Base HTTP data service to handle all requests. Client-side request caching is enabled at this layer.
 * @packageDocumentation 
 * 
 * @module DataService
 */
import { BASE_API_URL } from "../config";
import { AuthService } from "./AuthService";
import CacheService from "./CacheService";
import { MISSING_RESOURCE_ERROR, SUPPORTED_ACCEPT_TYPES } from "./DataService";
import { logError, logInfo } from "./TelemetryService";
import { ResponseData } from "../utils/ResponseData";

export class BaseDataService {

  private _auth: AuthService;

  constructor(auth: AuthService) {
    this._auth = auth;
  }

  private _fetch = async <T>(path: string, accept: SUPPORTED_ACCEPT_TYPES): Promise<ResponseData<T>> => {
    const accessToken = await this._auth.getToken();
    const response = await fetch(`${BASE_API_URL}${path}`, {
      method: 'GET',
      headers: {
        'Accept': accept,
        'Authorization': `Bearer ${accessToken}`,
      }
    });

    if (response.ok) {
      switch (accept) {
        case SUPPORTED_ACCEPT_TYPES.JSON:
          return new ResponseData<T>(response, await response.json());

        case SUPPORTED_ACCEPT_TYPES.EXCEL:
        case SUPPORTED_ACCEPT_TYPES.CSV:
        case SUPPORTED_ACCEPT_TYPES.GZ:
          return new ResponseData<T>(response, <T>(await response.blob() as unknown));

        default:
          return new ResponseData<T>(response, <T>(await response.text() as unknown));
      }
    }
    else if (response.status === 404) {
      logInfo(`HTTP 404 NOT FOUND in data service for: ${path}`);
      const notFoundResult = await response.json();
      if (notFoundResult) {
        throw new Error(`${notFoundResult.message ? notFoundResult.message : ''} Details: ${notFoundResult.detail ? notFoundResult.detail : ''}`);
      } else {
        throw new Error(`${MISSING_RESOURCE_ERROR}`);
      }
    }
    else {
      const error = new Error(`An API error occurred at ${path}. Details: [${response.status}] ${response.statusText}`);
      logError(`An API error occurred in data service for: ${path}`, false, error);
      throw error;
    }
  }

  /**
   * GET Wrapper to return JSON (cache-enabled), CSV or EXCEL, but returns ResponseData containing data and filename
   * @param path 
   */
  protected getResponseData = async <T>(path: string, as: SUPPORTED_ACCEPT_TYPES = SUPPORTED_ACCEPT_TYPES.JSON): Promise<ResponseData<T>> => {
    let result: ResponseData<T> = null;
    // JSON uses client-side cache; others don't
    if (as === SUPPORTED_ACCEPT_TYPES.JSON) {
      const CACHE_KEY = path;
      const cachedResponse = await CacheService.get<ResponseData<T>>(CACHE_KEY);

      if (cachedResponse) {
        // Found cached response - returning it
        result = cachedResponse;
      }
      else {
        // Nothing found in cache - fetching, caching, returning it
        const response = (await this._fetch<T>(path, as));
        result = await CacheService.set<ResponseData<T>>(CACHE_KEY, response);
      }
    }
    else {
      result = (await this._fetch<T>(path, as));
    }
    return result;
  }


  /**
   * GET Wrapper to return JSON (cache-enabled), CSV or EXCEL 
   * @param path 
   */
  protected get = async <T>(path: string, as: SUPPORTED_ACCEPT_TYPES = SUPPORTED_ACCEPT_TYPES.JSON): Promise<T> => {
    return (await this.getResponseData<T>(path, as)).data;
  }

  public getFile = async (path: string, as: SUPPORTED_ACCEPT_TYPES = SUPPORTED_ACCEPT_TYPES.JSON): Promise<ResponseData<Blob>> => {
    return this.getResponseData<Blob>(path, as);
  }

}
