import React from 'react';
import Section from '../../../components/Section/Section';
import { Link } from 'react-router-dom';
import { getHrefId } from '../../../utils/LinkUtils';
import { Stack } from '@fluentui/react/lib/Stack';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { ILink } from '../../../models/ILink';
import { ICDASHDomain, ICDASHClass } from '../../../models/ICDASH';

export interface IClassDomainScenarioSelectorProps { 
  standard: string;
  version: string;
  classId: string;
  domainId?: string;
  scenarioId?: string;
  isLoadingItem: boolean;
  isLoadingClass: boolean;
  classes: ICDASHClass[];
  domains?: ICDASHDomain[];
  scenarios?: ILink[];
  getLink: (standard: string, version: string, classId?: string, domainId?: string, scenarioId?: string, keepCurrentParams?: boolean) => string;
}

const ClassDomainScenarioSelector: React.FunctionComponent<IClassDomainScenarioSelectorProps> = ({ 
  standard, version, classId, domainId, scenarioId, isLoadingClass, isLoadingItem, getLink, classes, domains, scenarios
}) => {
  return (
    <Section>
      <Stack tokens={{ childrenGap: 10 }}>
        {/* Buttons for Classes */}
        <h4>Classes</h4>
        <div>
          {!isLoadingItem && classes && classes.length > 0
            ? classes.map((currentClass, idx) => {
                const currentClassId = getHrefId(currentClass._links.self);
                const link = getLink(standard, version, currentClassId);
                const isSelected = classId && classId.trim().toLowerCase() === currentClassId.trim().toLowerCase();
                return (
                  <Link key={idx} to={link}>
                    <DefaultButton text={currentClass.name} className={isSelected ? 'is-selected' : ''} />
                  </Link>
                );
              })
            : <Shimmer />
          }
        </div>

        {/*  Buttons for Domains */}
        {!isLoadingClass && domains && domains.length > 0 && <>
          <div>
            <h4>Domains</h4>
            {domains.map((currentDomain, idx) => {
                const currentDomainId = getHrefId(currentDomain._links.self);
                const link = getLink(standard, version, classId, currentDomainId);
                const isSelected = domainId && domainId.trim().toLowerCase() === currentDomainId.trim().toLowerCase();
                return (
                  <Link key={idx} to={link}>
                    <DefaultButton text={currentDomain.name} className={isSelected ? 'is-selected' : ''} />
                  </Link>
                );
            })}
          </div>
        </>}

        {/*  Buttons for Scenarios */}
        {!isLoadingClass && scenarios && scenarios.length > 0 && <>
          <div>
            <h4>Scenarios</h4>
            {scenarios.map((currentScenario, idx) => {
                const currentScenarioId = getHrefId(currentScenario);
                const link = getLink(standard, version, classId, domainId, currentScenarioId);
                const isSelected = scenarioId && scenarioId.trim().toLowerCase() === currentScenarioId.trim().toLowerCase();
                return (
                  <Link key={idx} to={link}>
                    <DefaultButton text={currentScenario.title} className={isSelected ? 'is-selected' : ''} />
                  </Link>
                );
            })}
          </div>
        </>}
      </Stack>
    </Section>
  );
}

export default ClassDomainScenarioSelector;
