import React from 'react';
import Section from '../../../components/Section/Section';
import { Link } from 'react-router-dom';
import { getHrefId } from '../../../utils/LinkUtils';
import { Stack } from '@fluentui/react/lib/Stack';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { IQRSItem } from '../../../models/IQRS';

export interface IItemSelectorProps {
  instrumentId: string,
  version: string;
  itemId: string;
  isLoadingElement: boolean;
  items: IQRSItem[];
  getLink: (instrumentId: string, version: string, itemId?: string) => string;
}

const ItemSelector: React.FunctionComponent<IItemSelectorProps> = ({
  instrumentId, version, itemId, isLoadingElement, items, getLink
}) => {
  return (
    <Section>
      <Stack tokens={{ childrenGap: 10 }}>
        {/* Buttons for Items */}
        <h4>Items</h4>
        <div>
          {!isLoadingElement && items
            ? items.map((currentItem, idx) => {
              const currentItemId = getHrefId(currentItem._links.self);
              const link = getLink(instrumentId, version, currentItemId);
              const isSelected = itemId && itemId.trim().toLowerCase() === currentItemId.trim().toLowerCase();
              return (
                <Link key={idx} to={link}>
                  <DefaultButton text={currentItem.itemCode} className={isSelected ? 'is-selected' : ''} />
                </Link>
              );
            })
            : <Shimmer />
          }
        </div>

      </Stack>
    </Section>
  );
}

export default ItemSelector;