/**
 * Data service for CDASH requests.
 * @packageDocumentation 
 * 
 * @module DataService-CDASH
 */
import { ICDASH, ICDASHClass } from '../../models/ICDASH';
import { BaseDataService } from '../BaseDataService';

export class CDASH extends BaseDataService {

  public getClassesByVersion = async (version: string): Promise<ICDASH> => {
    if (!version) return null;
    let result = await this.get<ICDASH>(`/api/mdr/cdash/${version}/classes?expand=true`);
    return result;
  }

  public getDomainsByVersion = async (version: string): Promise<ICDASH> => {
    if (!version) return null;
    let result = await this.get<ICDASH>(`/api/mdr/cdash/${version}/domains?expand=true`);
    return result;
  }

  public getClass = async (version: string, classId: string): Promise<ICDASHClass> => {
    if (!version || !classId) return null;
    let result = await this.get<ICDASHClass>(`/api/mdr/cdash/${version}/classes/${classId}`);
    return result;
  }

}
