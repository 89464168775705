import React from 'react';
import Section from '../../../components/Section/Section';
import { Stack } from '@fluentui/react/lib/Stack';
import { Label } from '@fluentui/react/lib/Label';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import { PivotKey } from '../../../models/PivotKey';
import { IADAMDataStructure, IADAMVariableSet } from '../../../models/IADAM';
import ImplementationGuideDocument from '../../../components/ImplementationGuideDocument/ImplementationGuideDocument';
import { ISections } from '../../../models/IDocuments';
import { capitalizeFirst } from '../../../utils/DataUtils';

export interface IDataStructureVariableSetProps {
  currentDataStructure: IADAMDataStructure;
  currentVariableSet: IADAMVariableSet;
  isLoadingItem: boolean;
  isLoadingDataStructure: boolean;
  selectedPivotKey: PivotKey;
  setSelectedPivotKey: (pivotKey: PivotKey) => void;
  isLoadingSections: boolean;
  sections: ISections
  standardType: string;
  version: string;
  standardSubtype?: string;
}

const DataStructureVariableSetTabs: React.FunctionComponent<IDataStructureVariableSetProps> = ({ 
  currentDataStructure, currentVariableSet, isLoadingDataStructure, isLoadingItem, selectedPivotKey, setSelectedPivotKey, isLoadingSections, sections, standardType, version, standardSubtype
}) => {

  return (
    <Section>
      {!isLoadingItem
        ? currentDataStructure && (!currentDataStructure.analysisVariableSets || (!isLoadingDataStructure && currentVariableSet)) && 
          <>
            <Pivot selectedKey={selectedPivotKey} onLinkClick={(pivotItem: PivotItem) => setSelectedPivotKey(pivotItem.props.itemKey as PivotKey)}>
              <PivotItem itemKey={PivotKey.DataStructure} headerText={currentDataStructure.name}>
                <Stack tokens={{ childrenGap: 20 }}>
                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack>
                      <Label>Name</Label>
                      <span>{currentDataStructure.label}</span>
                    </Stack>
                    {currentDataStructure.ordinal && (
                      <Stack>
                        <Label>Ordinal</Label>
                        <span>{currentDataStructure.ordinal}</span>
                      </Stack>
                    )}
                  </Stack>
                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack>
                      <Label>Class</Label>
                      <span>{currentDataStructure.class}</span>
                    </Stack>
                    {currentDataStructure.subClass && (
                      <Stack>
                        <Label>Sub Class</Label>
                        <span>{currentDataStructure.subClass}</span>
                      </Stack>
                    )}
                  </Stack>
                  <Stack horizontal tokens={{ childrenGap: 20 }} maxWidth={"50%"}>
                    {currentDataStructure.description && currentDataStructure.description.trim() !== "" &&
                      <Stack>
                        <Label>Description</Label>
                        <span>{currentDataStructure.description}</span>
                      </Stack>
                    }
                    <Stack>
                      <Label>Status</Label>
                      <span>{currentDataStructure.status || "Final"}</span>
                    </Stack>
                  </Stack>
                </Stack>
              </PivotItem>
              
              {!isLoadingDataStructure && currentVariableSet && (
                <PivotItem itemKey={PivotKey.VariableSet} headerText={currentVariableSet.name}>
                  <Stack tokens={{ childrenGap: 20 }}>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <Stack>
                        <Label>Name</Label>
                        <span>{currentVariableSet.label}</span>
                      </Stack>
                      {currentVariableSet.ordinal && (
                        <Stack>
                          <Label>Ordinal</Label>
                          <span>{currentVariableSet.ordinal}</span>
                        </Stack>
                      )}
                    </Stack>
                    {currentVariableSet.description && currentVariableSet.description.trim() !== "" && (
                      <Stack>
                        <Label>Description</Label>
                        <span>{currentVariableSet.description}</span>
                      </Stack>
                    )}
                  </Stack>
                </PivotItem>
              )}
              {!isLoadingSections && sections && !isLoadingDataStructure && currentDataStructure && (
                    sections.sections.map(sectionName => (
                    <PivotItem itemKey={`adam-${sectionName}-${currentDataStructure}`} headerText={capitalizeFirst(sectionName)}>
                          <ImplementationGuideDocument
                            sectionName={sectionName}
                            standardType={version.startsWith("adam") ? version.split("-")[0] : standardType}
                            version={version.startsWith("adam") ? version.split("-").slice(1).join("-") : version}
                            standardSubtype={standardSubtype}
                            documentStructure={currentDataStructure.name}>
                          </ImplementationGuideDocument>
                    </PivotItem>
                    )))}
            </Pivot>
          </>
        : <Shimmer />
      }          
    </Section>
  );
}

export default DataStructureVariableSetTabs;
