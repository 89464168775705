import { useContext, useEffect, useState } from "react";
import useAsyncData from "../../../hooks/useAsyncData";
import AppContext from "../../../components/AppContext/AppContext";
import { ISDTM, ISDTMClass, ISDTMDataset } from "../../../models/ISDTM";
import { INavItem } from "../../../models/INavItem";

export type SDTMData = {
  isLoadingItem: boolean;
  isLoadingClass: boolean;
  isLoadingDataset: boolean;
  isLoadingItemImplementedBy: boolean;
  currentItem: ISDTM;
  currentClass: ISDTMClass;
  currentDataset: ISDTMDataset;
  currentItemImplementedBy: INavItem[];
}

export default function useSDTMData(version: string, classId: string, datasetId: string): SDTMData {
  const { setError, dataService } = useContext(AppContext);
  const [ lastVersion, setLastVersion ] = useState<string>(null);
  
  // Fetch Item
  const { 
    isLoading: isLoadingItem, 
    data: currentItem,
    error: itemError,
    replace: replaceItem
  } = useAsyncData<ISDTM>(null, dataService.sdtm.getClassesByVersion, [version]);

  // Fetch Implemented By
  const getImplementedBy = async (): Promise<INavItem[]> => {
    if (currentItem && 'implementedBy' in currentItem._links && currentItem._links.implementedBy.length > 0) {
      return await Promise.all(currentItem._links.implementedBy.map(itemImplementedByLink => dataService.nav.getNavLinkFromHref(itemImplementedByLink.href)));
    }
    else return [];
  }
  const { 
    isLoading: isLoadingItemImplementedBy, 
    data: currentItemImplementedBy,
    error: itemImplementedByError,
    replace: replaceItemImplementedBy
  } = useAsyncData<INavItem[]>([], getImplementedBy, [currentItem]);

  // Fetch Class
  const { 
    isLoading: isLoadingClass, 
    data: currentClass,
    error: classError,
    replace: replaceClass
  } = useAsyncData<ISDTMClass>(null, dataService.sdtm.getClass, [version, classId]);

  // Fetch Dataset
  const { 
    isLoading: isLoadingDataset, 
    data: currentDataset,    
    error: datasetError,
    replace: replaceDataset
  } = useAsyncData<ISDTMDataset>(null, dataService.sdtm.getDataset, [version, datasetId]);

  // Clear Version if params become undefined/null, maintain lastVersion
  useEffect(() => {
    if (!version) {
      replaceItem(null);
      replaceItemImplementedBy(null);
    } 
    else if (lastVersion !== version) {
      setLastVersion(version);
    }
  });
  
  // Clear Class and Dataset if params become undefined/null
  useEffect(() => {
    if (!classId) replaceClass(null);
    if (!datasetId) replaceDataset(null);
  }, [classId, datasetId]);

  // Notify UI of Any Errors
  useEffect(() => {
    const combinedErrors = [itemError, classError, datasetError, itemImplementedByError].filter(e => e).join('; ');
    if (combinedErrors) {
      setError(`Unable to load data for SDTM`, combinedErrors);
    }
  }, [itemError, classError, datasetError, itemImplementedByError]);

  let output: SDTMData = {
    isLoadingItem, isLoadingClass, isLoadingDataset, isLoadingItemImplementedBy,
    currentItem, currentClass, currentDataset, currentItemImplementedBy,
  };
  if (lastVersion && lastVersion !== version) {
    output.currentItem = null;
    output.currentClass = null;
    output.currentDataset = null;
    output.currentItemImplementedBy = null;
  }
  return output;
}