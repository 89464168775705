import React from 'react';
import Section from '../../../components/Section/Section';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import { useHistory } from 'react-router-dom';
import { PivotKey } from '../../../models/PivotKey';
import { ICDASHDomain, ICDASHClass } from '../../../models/ICDASH';
import { ICDASHIGDomain, ICDASHIGScenario, ICDASHIGClass } from '../../../models/ICDASHIG';
import FieldsIG from './FieldsIG';
import Fields from './Fields';
import { getQueryString } from '../../../utils/LinkUtils';

export interface IFieldsTabsProps {
  isIG: boolean;
  isLoadingClass: boolean;
  currentClass: ICDASHClass | ICDASHIGClass;
  currentDomain: ICDASHDomain | ICDASHIGDomain;
  currentScenario?: ICDASHIGScenario;
  selectedPivotKey: PivotKey;
  setSelectedPivotKey: (pivotKey: PivotKey) => void;
}

const FieldsTabs: React.FunctionComponent<IFieldsTabsProps> = ({ 
  isIG, isLoadingClass, currentClass, currentDomain, currentScenario, selectedPivotKey, setSelectedPivotKey
}) => {
  const history = useHistory();
  const FieldsComponent = isIG ? FieldsIG : Fields;
  const hasClassFields = currentClass && currentClass.cdashModelFields;
  const hasDomainFields = currentDomain && currentDomain.fields;
  const hasScenarioFields = currentScenario && currentScenario.fields;

  const onPivotLinkClick = (pivotItem: PivotItem) => {
    //Remove query string params if there are any when switching tabs
    if (getQueryString(window.location)) history.replace({ search: '' });
    
    setSelectedPivotKey(pivotItem.props.itemKey as PivotKey);
  };
  
  const queryString = getQueryString(window.location)
  if (queryString && !isIG) {
    setSelectedPivotKey(PivotKey.Class);
  }

  return (    
    !isLoadingClass 
      ? (hasClassFields || hasDomainFields || hasScenarioFields) 
        ? <Section>
            <Pivot selectedKey={selectedPivotKey} onLinkClick={onPivotLinkClick}>
              {!isLoadingClass && currentClass && currentClass.cdashModelFields && (
                <PivotItem itemKey={PivotKey.Class} headerText={currentClass.name}>
                  <FieldsComponent 
                    isLoading={isLoadingClass}
                    fields={currentClass.cdashModelFields as any[]}
                  />
                </PivotItem>
              )}

              {!isLoadingClass && currentDomain && currentDomain.fields && (
                <PivotItem itemKey={PivotKey.Domain} headerText={currentDomain.name}>
                  <FieldsComponent 
                    isLoading={isLoadingClass}
                    fields={currentDomain.fields as any[]}
                    isDomain={true}
                  />
                </PivotItem>
              )}
              
              {!isLoadingClass && currentScenario && currentScenario.fields && (
                <PivotItem itemKey={PivotKey.Scenario} headerText={currentScenario.scenario}>
                  <FieldsComponent 
                    isLoading={isLoadingClass}
                    fields={currentScenario.fields as any[]}
                  />
                </PivotItem>
              )}
            </Pivot>
          </Section>
        : null
      : <Section><Shimmer /></Section>       
  );
}

export default FieldsTabs;
