/**
 * Generic component to render tabular list data.
 * @packageDocumentation 
 */
import React, { useState, useEffect, useReducer } from 'react';
import './List.scss';
import { useLocation } from 'react-router-dom';
import { IColumn, DetailsListLayoutMode, ConstrainMode, IDetailsRowProps, DetailsRow } from '@fluentui/react/lib/DetailsList';
import { ShimmeredDetailsList } from '@fluentui/react/lib/ShimmeredDetailsList';
import { SelectionMode } from '@fluentui/react/lib/Utilities';
import { Label } from '@fluentui/react/lib/Label';
import { Icon } from '@fluentui/react/lib/Icon';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import ExportButton from '../ExportButton/ExportButton';
import { Stack } from '@fluentui/react/lib/Stack';
import queryString from 'query-string';
import ListPanel from './ListPanel';
import { parseBoolString } from '../../utils/DataUtils';
import { IExportConfig } from '../../models/IExportConfig';
import Multiline from '../Multiline/Multiline';
import { Location } from 'history';

export interface IListProps {
  items: IItem[];
  columns: IColumn[];
  isLoading: boolean;
  exportConfig?: IExportConfig[];
  selectedItem?: IItem;
  setSelectedItem?: (item: IItem) => void;
}

export interface IItem {
  key: string;
  [ fieldName: string ]: string | number;
}

export interface IFilterProps {
  filterParam: string;
  filterFieldsParam: string[];
  filterIsExact: boolean;
}

const PAGE_SIZES = [ 50, 100, 250 ];

const getPagedItems = (items: IItem[], pageSize: number, currentPage: number): IItem[] => {
  if (items.length <= pageSize) return items;
  const pageStartIdx = (pageSize * currentPage) - pageSize;
  const pageEndIdx = pageStartIdx + pageSize;
  return items.slice(pageStartIdx, pageEndIdx >= (items.length - 1) ? undefined : pageEndIdx);
}

const getFilterProps = (location: Location<{}>): IFilterProps => {
  const queryParams = queryString.parse(location.search);
  const filterParam =
    queryParams && queryParams.filter ? `${queryParams.filter}` : "";
  const filterFieldsParam =
    queryParams && queryParams.filterFields
      ? `${queryParams.filterFields}`.split(",")
      : [];
  const filterIsExact =
    queryParams && queryParams.filterIsExact
      ? parseBoolString(`${queryParams.filterIsExact}`)
      : false;
  return { filterParam, filterFieldsParam, filterIsExact };
};

const List: React.FunctionComponent<IListProps> = ({ items, columns, isLoading, exportConfig, selectedItem, setSelectedItem }) => {
  const location = useLocation();
  const [ allItems, setAllItems ] = useState<IItem[]>([]);
  const [ activeItems, setActiveItems ] = useState<IItem[]>([]);
  const [ currentPageItems, setCurrentPageItems ] = useState<IItem[]>([]);
  const [ activeColumns, setActiveColumns ] = useState<IColumn[]>(columns);
  const [ currentPage, setCurrentPage ] = useState<number>(1);
  const [ currentPageSize, setCurrentPageSize ] = useState<number>(PAGE_SIZES[0]);
  const [ currentFilter, setCurrentFilter ] = useState<IFilterProps>(getFilterProps(location));
  const [ currentSort, setCurrentSort ] = useState<{field: string, isDescending: boolean}>(null);
  const [ forceUpdateToken, forceUpdate ] = useReducer(x => x + 1, 0);
  const [ localSelectedItem, setLocalSelectedItem ] = useState<IItem>(null);

  const lastPageNumber = Math.ceil(activeItems.length / currentPageSize);
  const firstItemNumber = ((currentPageSize * currentPage) - currentPageSize) + 1;
  let lastItemNumber = (firstItemNumber + currentPageSize) - 1;
  lastItemNumber = (activeItems.length <= lastItemNumber) ? activeItems.length : lastItemNumber;

  const _selectedItem = selectedItem || localSelectedItem;
  const _setSelectedItem = setSelectedItem || setLocalSelectedItem;

  const metaPanelColumn: IColumn = { key: 'meta', name: '', minWidth: 30, maxWidth: 30, isPadded: false, onRender: (item?: any, index?: number, column?: IColumn) => {
    return (
      <Icon 
        className="list-panel-icon" 
        iconName="OpenPane" 
        ariaLabel="Open Panel" 
        title="Open Panel" 
        onClick={() => _setSelectedItem(item)} 
      />
    )
  }}

  useEffect(() => {
    setCurrentPage(1);
    setCurrentFilter(getFilterProps(location));
  }, [location]);

  useEffect(() => {
    setAllItems(items);
    setActiveItems(items);
    setCurrentPageItems(getPagedItems(items, currentPageSize, currentPage))
    setCurrentPage(1);
    forceUpdate();
  }, [items]);

  useEffect(() => {
    let newActiveItems = [ ...allItems ];
    let newActiveColumns = [ ...activeColumns ];

    if (currentSort) {
      let sortDirA = currentSort.isDescending ? 1 : -1,
          sortDirB = currentSort.isDescending ? -1 : 1;
  
      newActiveItems.sort((itemA, itemB): number => {
        return itemA[currentSort.field] === itemB[currentSort.field] ? 0 : itemA[currentSort.field] < itemB[currentSort.field] ? sortDirA : sortDirB;
      });
  
      for (let col of newActiveColumns) {
        if (col.key === currentSort.field) {
          col.isSorted = true;
          col.isSortedDescending = currentSort.isDescending;
        }
        else {
          col.isSorted = false;
          col.isSortedDescending = undefined;
        }
      }
    }

    if (currentFilter.filterParam) {
      const trimmedCurrentFilter = currentFilter.filterParam.trim();
      const loweredCurrentFilter = trimmedCurrentFilter.toLowerCase();
      if (currentFilter.filterFieldsParam && currentFilter.filterFieldsParam.length > 0) { 
        newActiveItems = newActiveItems.filter(i => {
          if (currentFilter.filterIsExact) {
            return currentFilter.filterFieldsParam.some(ff => i[ff] ? `${i[ff]}`.trim() === trimmedCurrentFilter : false);
          }
          else {
            return currentFilter.filterFieldsParam.some(ff => i[ff] ? `${i[ff]}`.toLowerCase().indexOf(loweredCurrentFilter) > -1 : false);
          }
        });
      }
      else {
        newActiveItems = newActiveItems.filter(i => Object.values(i).some(v => `${v}`.toLowerCase().indexOf(loweredCurrentFilter) > -1));
      }
    }

    setCurrentPageItems(getPagedItems(newActiveItems, currentPageSize, currentPage));
    setActiveColumns(newActiveColumns);
    setActiveItems(newActiveItems);
    
  }, [currentPage, currentPageSize, currentFilter, currentSort, forceUpdateToken]);

  const onRenderRow = (rowProps: IDetailsRowProps) => {
    let rowClassName = "list-row";
    if (_selectedItem && rowProps.item.key === _selectedItem.key) {
      rowClassName += " selected";
    }

    return (
      <DetailsRow {...rowProps} className={rowClassName} />
    )
  }

  const onRenderItemColumn = (item: IItem, index: number, column: IColumn) => {
    const fieldContent = item[column.fieldName as keyof IItem] as string;
    return <Multiline text={fieldContent} />;
  }

  const onColumnHeaderClick = (ev: any, column: IColumn) => {
    if (column) {
      const newSortIsDescending = column.isSortedDescending === undefined ? false : !column.isSortedDescending;
      setCurrentSort({ field: column.key, isDescending: newSortIsDescending });
    }
  }

  const onPageBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  const onPageForward = () => {
    if (currentPage < lastPageNumber) {
      setCurrentPage(currentPage + 1);
    }
  }

  const onChangePageSize = (event: any, option?: IDropdownOption) => {
    setCurrentPageSize(parseInt(option.text));
    setCurrentPage(1);
  }

  const onFilterChange = (ev: any, value: string) => {
    setCurrentPage(1);
    setCurrentFilter({
      filterParam: value,
      filterFieldsParam: [],
      filterIsExact: false,
    });
  }

  const onFilterClear = () => {
    setCurrentFilter({
      ...currentFilter,
      filterParam: ""
    });
  }

  if (items.length === 0 && !isLoading) return null;

  return (
    <div className="list">
      <div className="list-top">
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <SearchBox 
            className="list-filter-input" 
            ariaLabel="Filter" 
            placeholder="Filter results" 
            iconProps={{iconName: 'Filter'}}
            value={currentFilter.filterParam}
            onChange={onFilterChange} 
            onClear={onFilterClear}          
          />
          {undefined !== exportConfig && <ExportButton>{exportConfig}</ExportButton>}
        </Stack>
      </div>
      <ShimmeredDetailsList
        items={currentPageItems}
        columns={[metaPanelColumn, ...activeColumns]}
        selectionMode={SelectionMode.none}
        enableShimmer={isLoading}
        ariaLabelForShimmer="Content is being fetched"
        ariaLabelForGrid="Item details"
        listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
        onColumnHeaderClick={onColumnHeaderClick}
        layoutMode={DetailsListLayoutMode.justified}
        constrainMode={ConstrainMode.horizontalConstrained}
        onRenderRow={onRenderRow}
        onRenderItemColumn={onRenderItemColumn}
      />
      {!isLoading && activeItems.length > PAGE_SIZES[0] && (
        <div className="list-bottom">
          <Dropdown
            label="Rows per page:"            
            options={PAGE_SIZES.map<IDropdownOption>(ps => ({ key: ps, text: `${ps}` }))}
            selectedKey={currentPageSize}
            defaultValue={currentPageSize}
            onChange={onChangePageSize}
            className="list-pagination-pagesize"
          />
          <Label className="list-pagination-itemlabel">{firstItemNumber}-{lastItemNumber} of {activeItems.length}</Label>
          <Icon 
            iconName="PageLeft" 
            className={`list-pagination-icon${ currentPage === 1 ? ' disabled' : ''}`} 
            onClick={onPageBack}
          />
          <Label className="list-pagination-pagelabel">Page {currentPage} of {lastPageNumber}</Label>
          <Icon 
            iconName="PageRight" 
            className={`list-pagination-icon${ currentPage === lastPageNumber ? ' disabled' : ''}`} 
            onClick={onPageForward}
          /> 
        </div>
      )}
      <ListPanel 
        isOpen={!!_selectedItem}
        item={_selectedItem}
        columns={activeColumns}
        title="Details"
        onClose={() => _setSelectedItem(null)}
      />
    </div>
  )
}

export default List;
