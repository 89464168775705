/**
 * Core component for rendering QRS screens.
 * @packageDocumentation 
 */
import React, { useState, useEffect, useMemo, useContext } from 'react';
import Section from '../../components/Section/Section';
import { useParams, Link, useLocation } from 'react-router-dom';
import { getHrefId, getQueryString } from '../../utils/LinkUtils';
import { Stack } from '@fluentui/react/lib/Stack';
import { Label } from '@fluentui/react/lib/Label';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import ExportButton from '../../components/ExportButton/ExportButton';
import ItemSelector from './components/ItemSelector';
import ItemSection from './components/ItemSection';
import ResponsesSection from './components/ResponsesSection';
import useQRSData from './hooks/useQRSData';
import useQRSRouteObserver from './hooks/useQRSRouteObserver';
import { ExportTypes, IExportConfig } from '../../models/IExportConfig';
import AppContext from '../../components/AppContext/AppContext';
import { ILink } from '../../models/ILink';

export const getLink = (instrumentId: string, version: string, itemId?: string, keepCurrentParams: boolean = false): string => {
  let link = `/mdr/qrs/instruments/${instrumentId}`;
  if (version) link += `/versions/${version}`;
  if (itemId) link += `/items/${itemId}`;
  if (keepCurrentParams) link += getQueryString(window.location);
  return link;
}

export const getLatestCTLink = (link: ILink | string) => {
  if (link) {
    let href = typeof link === 'object' ? link.href : link;
    const codelistLink: string = href.split('/terms/')[0];
    const codelistItemLink: string = href;
    const codelistId = getHrefId(codelistLink);
    const codelistItemId = getHrefId(codelistItemLink);
    const packageAndCodelistPath = `/mdr/ct/latest/packages/${codelistLink.replace('/mdr/root/ct/', '')}`;
    const packageAndCodelistItemPath = `/mdr/ct/latest/packages/${codelistItemLink.replace('/mdr/root/ct/', '')}`;
    return <>
      <Stack horizontal>
        <Link key={codelistId} to={packageAndCodelistPath}>{codelistId}</Link>
        .
        <Link key={codelistItemId} to={packageAndCodelistItemPath}>{codelistItemId}</Link>
      </Stack>
    </>;
  }
}

export const getLatestCodelistItemLink = (link: ILink | string) => {
  if (link) {
    let href = typeof link === 'object' ? link.href : link;
    const codelistItemLink: string = href;
    return `/mdr/ct/latest/packages/${codelistItemLink.replace('/mdr/root/ct/', '')}`;
  }
}

export const getCodelistItemID = (link: ILink | string) => {
  if (link) {
    let href = typeof link === 'object' ? link.href : link;
    const codelistItemLink: string = href;
    return getHrefId(codelistItemLink);
  }
}
const defaultPageTitle = "QRS";

const QRSComponent: React.FunctionComponent = () => {
  const location = useLocation();
  const { instrumentId, version, itemId } = useParams<any>();
  const [pageTitle, setPageTitle] = useState<string>(defaultPageTitle);
  const { dataService } = useContext(AppContext);
  const exportConfig = useMemo<IExportConfig[]>(
    () => [
      {
        exportType: ExportTypes.xlsx,
        requiredVariables: [instrumentId, version],
        request: `/api/mdr/qrs/instruments/${instrumentId}/versions/${version}`,
        exportFileName: `${pageTitle.replace(/\s/g, "_")}.xlsx`,
      },
      {
        exportType: ExportTypes.csv,
        requiredVariables: [instrumentId, version],
        request: `/api/mdr/qrs/instruments/${instrumentId}/versions/${version}`,
        exportFileName: `${pageTitle.replace(/\s/g, "_")}.csv`,
      },
      {
        exportType: ExportTypes.diff,
        requiredVariables: [instrumentId, version],
        request: `/api/mdr/diff/qrs/${instrumentId}/${version}`,
      },
    ],
    [instrumentId, version, pageTitle]
  );

  // Fetch Page Title
  useEffect(() => {
    dataService.nav.getNavLinkFromHref(getLink(instrumentId, version)).then(page => {
      if (page) setPageTitle(page.shortTitle)
    });
  }, [instrumentId, version]);

  // Fetch QRS Data
  const {
    isLoadingElement, isLoadingItem,
    currentElement, currentItem,
  } = useQRSData(instrumentId, version, itemId);

  // Handle QRS Route Changes
  useQRSRouteObserver(instrumentId, version, itemId, currentElement, getLink);

  return (
    <>
      {!isLoadingElement && currentElement
        ? <Section pageTitle={pageTitle} link={currentElement.qrsSupplement}>
          <Stack tokens={{ childrenGap: 20 }}>
            <Stack horizontal horizontalAlign='space-between'>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack>
                  <Label>Type</Label>
                  <span>{currentElement.instrumentType}</span>
                </Stack>
                <Stack>
                  <Label>Status</Label>
                  <span className={currentElement.registrationStatus === "Draft" ? "draft-status" : ""}>{currentElement.registrationStatus}</span>
                </Stack>
                <Stack>
                  <Label>Copyright</Label>
                  <span>{currentElement.copyrightStatus}</span>
                </Stack>
                <Stack>
                  <Label>Effective Date</Label>
                  <span>{currentElement.effectiveDate}</span>
                </Stack>
                <Stack>
                  <Label>Category</Label>
                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                    {currentElement.shortNameCAT}
                    {' '}
                    (<Link key={getCodelistItemID(currentElement._links.instrumentCAT)}
                      to={getLatestCodelistItemLink(currentElement._links.instrumentCAT)}>
                      {getCodelistItemID(currentElement._links.instrumentCAT)}
                    </Link>)
                  </Stack>
                </Stack>
              </Stack>
              <ExportButton>{exportConfig}</ExportButton>
            </Stack>
            <Stack>
              <Label>Label</Label>
              <span>{currentElement.label}</span>
            </Stack>
            <Stack>
              <Label>Description</Label>
              <span>{currentElement.description}</span>
            </Stack>
          </Stack>
        </Section>
        : <Section pageTitle={pageTitle}>
          {[1, 2].map(idx => (
            <Shimmer key={idx} styles={{ root: { minHeight: 17 } }} shimmerElements={[
              { type: ShimmerElementType.line, width: 50, height: 8 },
              { type: ShimmerElementType.gap, width: 20 },
              { type: ShimmerElementType.line, width: 50, height: 8 },
              { type: ShimmerElementType.gap, width: 20 },
              { type: ShimmerElementType.line, width: 50, height: 8 },
              { type: ShimmerElementType.gap, width: '100%' },
            ]} />
          ))}
        </Section>
      }


      <ItemSelector
        itemId={itemId}
        items={currentElement ? currentElement.items : null}
        isLoadingElement={isLoadingElement}
        instrumentId={instrumentId}
        version={version}
        getLink={getLink}
      />

      {undefined !== isLoadingItem && (
        <ItemSection
          currentItem={currentItem}
          isLoadingItem={isLoadingItem}
        />
      )}

      {undefined !== isLoadingItem && (
        <ResponsesSection
          currentItem={currentItem}
          isLoadingItem={isLoadingItem}
        />
      )}

    </>
  );
}

export default QRSComponent;
