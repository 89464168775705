/**
 * Generic React hook for making search requests.
 * @packageDocumentation 
 * 
 * @module useSearchResults
 */
 import { useContext, useEffect } from "react";
 import useAsyncData from "./useAsyncData";
 import AppContext from "../components/AppContext/AppContext";
 import { ISectionDocumentsResponse } from "../models/IDocuments";
 
 export type DocumentData = {
   isLoadingDocument: boolean;
   response: ISectionDocumentsResponse;
 }
 
 export default function useIGDocuments(
    standardType: string, version: string, documentSection: string, standardStructure?: string, useCase?: string, standardSubtype?: string
  ): DocumentData {
   const { setError, dataService } = useContext(AppContext);

   // Fetch Results
   const { 
     isLoading: isLoadingDocument,
     data: response,
     error,
     replace
   } = useAsyncData<ISectionDocumentsResponse>(null, dataService.igDocuments.getDocumentsForSection, [standardType, version, documentSection, standardStructure, useCase, standardSubtype]);
 
   // Notify UI of Any Errors
   useEffect(() => {
     let responseError = response && 'error' in response && 'message' in response['error'] ? response['error']['message'] : null;
     if (error || responseError) {
       replace(null);
       setError(`Unable to document`, error || responseError);
     }
   });
 
   let output: DocumentData = {
     isLoadingDocument, response
   };
   return output
 }
