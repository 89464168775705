/**
 * Global search results panel component that renders results when a user performs a search. Responsible for instrumenting the filters and pagination component.
 * @packageDocumentation 
 */
import * as React from 'react';
import { useContext } from 'react';
import './SearchResults.scss';
import AppContext from '../AppContext/AppContext';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { Stack } from '@fluentui/react/lib/Stack';
import { ActionButton } from '@fluentui/react/lib/Button';
import { ISearchParams } from '../../models/ISearch';
import useSearchResults from '../../hooks/useSearchResults';
import SearchResultItem from './SearchResultItem';
import SearchFilters from './SearchFilters';
import SearchPagination from './SearchPagination';

export interface ISearchResultsProps {
  isVisible: boolean;
  searchParams: ISearchParams;
}

const SearchResults: React.FC<ISearchResultsProps> = ({ isVisible, searchParams }) => {
  const { response: searchResponse, isLoading } = useSearchResults(searchParams);
  const { closeSearch } = useContext(AppContext);

  const onRenderPanelNavigation = () => (
    <ActionButton 
      iconProps={{ iconName: 'Cancel' }} 
      onClick={closeSearch} 
      ariaLabel="Close search results"
      className="search-results-close"
    >Close search results</ActionButton>
  );

  const renderShimmers = () => {
    let shimmerItems = [];
    for (let index = 0; index < 10; index++) {
      shimmerItems.push(
        <SearchResultItem key={`fakeitem-${index}`} />
      )
    }
    return shimmerItems;
  };

  return (
    <Panel
      isOpen={isVisible}
      type={PanelType.smallFluid}
      isLightDismiss={false}
      onRenderNavigation={onRenderPanelNavigation}      
      layerProps={{ 
        hostId: "main"
      }}
      styles={{
        content: "search-results-panel-content",
        scrollableContent: "search-results-panel-scrollable-content",
        main: { 
          animation: "none"
        },
      }}
      focusTrapZoneProps={{
        forceFocusInsideTrap: false,
        firstFocusableSelector: "search-results-filters-dropdown"
      }}
    >
      {isVisible && <>
        <Stack className="search-results-section">
          <Stack className="search-results-inner" horizontal horizontalAlign="space-between">
            <Stack verticalAlign="space-between">
              <h1>Search Results</h1>
              {!isLoading && searchResponse && <div>
                <strong>{searchResponse.totalHits.toLocaleString()}</strong> results for "{searchParams.text}"
              </div>}
            </Stack>
            <Stack tokens={{ childrenGap: 10 }}>
              <span>Narrow search results</span>
              <SearchFilters searchParams={searchParams} searchResponse={searchResponse} />
            </Stack>
          </Stack>
        </Stack>
        <Stack className="search-results-section" tokens={{ childrenGap: 20 }}>
          <Stack className="search-results-inner">
            {isLoading && renderShimmers()}
            {!isLoading && searchResponse && searchResponse.hits.map((r, index) => (
              <SearchResultItem key={`sr-${index}-${r.href}`} item={r} />
            ))}
          </Stack>
          <SearchPagination searchParams={searchParams} searchResponse={searchResponse} />
        </Stack>
      </>}
    </Panel>
  )
}

export default SearchResults;