/**
 * Base web app entry point. React initialization. Instruments telemetry, authentication, and app context.
 * @packageDocumentation 
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Customizer } from '@fluentui/react/lib/Utilities';
import App from './App';
import 'whatwg-fetch';
import * as serviceWorker from './serviceWorker';
import customTheme from './utils/FabricTheme';
import { loadTheme } from '@fluentui/react/lib/Styling';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import './index.scss';
import './utils/FabricOverrides.scss';
import TelemetryProvider from './components/TelemetryProvider/TelemetryProvider';
import AppContextProvider from './components/AppContext/AppContextProvider';
import AuthenticationProvider from './components/AuthenticationProvider/AuthenticationProvider';

loadTheme(customTheme);
initializeIcons();

ReactDOM.render(
  <React.Fragment>
    <TelemetryProvider>
      <AppContextProvider>
        <AuthenticationProvider>
          <Customizer>
            <App />
          </Customizer>
        </AuthenticationProvider>
      </AppContextProvider>
    </TelemetryProvider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
