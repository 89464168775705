/**
 * Core component for rendering ADAM and ADAMIG screens.
 * @packageDocumentation 
 */
import React, { useState, useEffect, useContext } from 'react';
import Section from '../../components/Section/Section';
import { useParams, Link } from 'react-router-dom';
import { Stack } from '@fluentui/react/lib/Stack';
import { Label } from '@fluentui/react/lib/Label';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import { PivotKey } from '../../models/PivotKey';
import useADAMData from './hooks/useADAMData';
import useADAMRouteObserver from './hooks/useADAMRouteObserver';
import DataStructureVariableSetSelector from './components/DataStructureVariableSetSelector';
import DataStructureVariableSetTabs from './components/DataStructureVariableSetTabs';
import VariablesTabs from './components/VariablesTabs';
import { getADaMLink } from '../../utils/LinkUtils';
import AppContext from '../../components/AppContext/AppContext';
import StandardExportButton from '../../components/ExportButton/StandardExportButton';


export interface ADAMComponentProps {
	getLinkOverride: (standard: string, version: string, dataStructureId?: string, variableSetId?: string, keepCurrentParams?: boolean) => string;
}
const defaultPageTitle = "ADAM";

const ADAMComponent: React.FunctionComponent<ADAMComponentProps> = ({getLinkOverride}) => {
  const { standard, version, productSubtype, dataStructureId, variableSetId } = useParams<any>();
  const [selectedMetaPivotKey, setSelectedMetaPivotKey] = useState<PivotKey>(PivotKey.Class);
  const [pageTitle, setPageTitle] = useState<string>(defaultPageTitle);
  const { dataService } = useContext(AppContext);
  const getLinkFunc = getLinkOverride != null ? getLinkOverride : getADaMLink

  // Fetch Page Title
  useEffect(() => {
    dataService.nav.getNavLinkFromHref(getLinkFunc(standard, version)).then(page => {
      if (page) setPageTitle(page.shortTitle)
    });
  }, [version]);

  // Fetch ADAM Data
  const {
    isLoadingItem, isLoadingDataStructure, isLoadingVariableSet, isLoadingImplementedBy, isLoadingImplements, isLoadingSections,
    currentItem, currentDataStructure, currentVariableSet, currentItemImplementedBy, currentItemImplements, sections
  } = useADAMData(standard, version, productSubtype, dataStructureId, variableSetId);

  // Handle ADAM Route Changes
  const isLoading = (isLoadingItem || isLoadingDataStructure || isLoadingVariableSet);
  useADAMRouteObserver(standard, version, dataStructureId, variableSetId, isLoading, currentItem, currentDataStructure, getLinkFunc);

  // Update Selected Tab
  useEffect(() => {
    if (currentVariableSet) {
      setSelectedMetaPivotKey(PivotKey.VariableSet);
    }
    else {
      setSelectedMetaPivotKey(PivotKey.DataStructure);
    }
  }, [currentDataStructure, currentVariableSet]);

  return (
    <>
      <Section pageTitle={pageTitle}>
        {!isLoadingItem && !isLoadingImplementedBy && !isLoadingImplements && currentItem
          ? <>
            <Stack horizontal horizontalAlign='space-between'>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack>
                  <Label>Status</Label>
                  <span className={currentItem.registrationStatus === "Draft" ? "draft-status" : ""}>{currentItem.registrationStatus}</span>
                </Stack>
                <Stack>
                  <Label>Effective Date</Label>
                  <span>{currentItem.effectiveDate}</span>
                </Stack>
                {currentItemImplements && (
                  <Stack>
                    <Label>Implements</Label>
                    <span>
                      <Link to={currentItemImplements.href}>
                        {currentItemImplements.shortTitle}
                      </Link>
                    </span>
                  </Stack>
                )}
                {currentItemImplementedBy.length > 0 && (
                  <Stack>
                    <Label>Implemented By</Label>
                    <Stack horizontal tokens={{ childrenGap: 14 }}>
                      {currentItemImplementedBy.map((link, index) => (
                        <Link key={`adam-ib-${index}-${link.href}`} to={link.href}>
                          {link.shortTitle}
                        </Link>
                      ))}
                    </Stack>
                  </Stack>
                )}
              </Stack>
              <StandardExportButton
                pageTitle={pageTitle}
                standard={standard}
                version={version}
                productSubtype={productSubtype}
              />
            </Stack>
          </>
          : <>
            {[1, 2].map(idx => (
              <Shimmer key={idx} styles={{ root: { minHeight: 17 } }} shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 8 },
                { type: ShimmerElementType.gap, width: 20 },
                { type: ShimmerElementType.line, width: 50, height: 8 },
                { type: ShimmerElementType.gap, width: 20 },
                { type: ShimmerElementType.line, width: 50, height: 8 },
                { type: ShimmerElementType.gap, width: '100%' },
              ]} />
            ))}
          </>
        }
      </Section>

      <DataStructureVariableSetSelector
        dataStructureId={dataStructureId}
        variableSetId={variableSetId}
        dataStructures={currentItem ? currentItem.dataStructures : null}
        variableSets={currentDataStructure ? currentDataStructure.analysisVariableSets : null}
        isLoadingItem={isLoadingItem}
        isLoadingDataStructure={isLoadingDataStructure}
        standard={standard}
        version={version}
        getLink={getLinkFunc}
      />

      {undefined !== isLoadingItem && (
        <DataStructureVariableSetTabs
          currentDataStructure={currentDataStructure}
          currentVariableSet={currentVariableSet}
          isLoadingItem={isLoadingItem}
          isLoadingDataStructure={isLoadingDataStructure}
          selectedPivotKey={selectedMetaPivotKey}
          setSelectedPivotKey={setSelectedMetaPivotKey}
          isLoadingSections={isLoadingSections}
          sections={sections}
	        standardType={standard}
	        version={version}
          standardSubtype={productSubtype}
        />
      )}

      {undefined !== isLoadingDataStructure && (
        <VariablesTabs
          currentVariableSet={currentVariableSet}
          isLoadingVariableSet={isLoadingVariableSet}
        />
      )}

    </>
  );
}

export default ADAMComponent;
