/**
 * Core component for rendering SDTMIG screens.
 * @packageDocumentation 
 */
import React, { useState, useEffect, useContext } from 'react';
import Section from '../../components/Section/Section';
import { useParams, Link, useLocation } from 'react-router-dom';
import { getPreferredTab, getSDTMIGLink } from '../../utils/LinkUtils';
import { Stack } from '@fluentui/react/lib/Stack';
import { Label } from '@fluentui/react/lib/Label';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import ClassDatasetSelector from './components/ClassDatasetSelector';
import ClassDatasetTabs from './components/ClassDatasetTabs';
import VariablesTabs from './components/VariablesTabs';
import { PivotKey } from '../../models/PivotKey';
import useSDTMIGData from './hooks/useSDTMIGData';
import useSDTMRouteObserver from './hooks/useSDTMRouteObserver';
import { TabulationType } from '../../models/TabulationType';
import AppContext from '../../components/AppContext/AppContext';
import StandardExportButton from '../../components/ExportButton/StandardExportButton';


export interface SDTMIGComponentProps {
	getLinkOverride: (standard: string, version: string, classId?: string, datasetId?: string, keepCurrentParams?: boolean) => string;
}

const defaultPageTitle = "SDTMIG";

const SDTMIGComponent: React.FunctionComponent<SDTMIGComponentProps> = ({getLinkOverride}) => {
  const location = useLocation();
  const { standard, version, productSubtype, classId, datasetId } = useParams<any>();
  const [pageTitle, setPageTitle] = useState<string>(defaultPageTitle);
  const [selectedMetaPivotKey, setSelectedMetaPivotKey] = useState<PivotKey>(PivotKey.Class);
  const [selectedListPivotKey, setSelectedListPivotKey] = useState<PivotKey>(PivotKey.Class);
  const { dataService } = useContext(AppContext);
  const getLinkFunc = getLinkOverride != null ? getLinkOverride : getSDTMIGLink;

  // Fetch Page Title
  useEffect(() => {
    dataService.nav.getNavLinkFromHref(getLinkFunc(standard, version)).then(page => {
      if (page) setPageTitle(page.shortTitle)
    });
  }, [version]);

  // Fetch SDTMIG Data
  const {
    isLoadingItem, isLoadingClass, isLoadingDataset, isLoadingItemImplements, isLoadingSections,
    currentItem, currentClass, currentDataset, currentItemImplements, sections
  } = useSDTMIGData(TabulationType.SDTMIG, standard, version, productSubtype, classId, datasetId);

  // Handle SDTMIG Route Changes
  useSDTMRouteObserver(standard, version, classId, datasetId, currentItem, getLinkFunc);

  // Update Selected Tab
  useEffect(() => {
    const preferredTab = getPreferredTab(location);
    if (preferredTab) {
      setSelectedMetaPivotKey(preferredTab);
      setSelectedListPivotKey(preferredTab);
    }
    else if (currentDataset) {
      setSelectedMetaPivotKey(PivotKey.Dataset);
      setSelectedListPivotKey(PivotKey.Dataset);
    }
    else {
      setSelectedMetaPivotKey(PivotKey.Class);
      setSelectedListPivotKey(PivotKey.Class);
    }
  }, [currentClass, currentDataset]);

  return (
    <>
      <Section pageTitle={pageTitle}>
        {!isLoadingItem && !isLoadingItemImplements && currentItem
          ? <>
            <Stack horizontal horizontalAlign='space-between'>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack>
                  <Label>Status</Label>
                  <span className={currentItem.registrationStatus === "Draft" ? "draft-status" : ""}>{currentItem.registrationStatus}</span>
                </Stack>
                <Stack>
                  <Label>Effective Date</Label>
                  <span>{currentItem.effectiveDate}</span>
                </Stack>
                {currentItemImplements && (
                  <Stack>
                    <Label>Implements</Label>
                    <span>
                      <Link to={currentItemImplements.href}>
                        {currentItemImplements.shortTitle}
                      </Link>
                    </span>
                  </Stack>
                )}
              </Stack>
              <StandardExportButton
                pageTitle={pageTitle}
                standard={standard}
                version={version}
                productSubtype={productSubtype}
              />
            </Stack>
          </>
          : <>
            {[1, 2].map(idx => (
              <Shimmer key={idx} styles={{ root: { minHeight: 17 } }} shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 8 },
                { type: ShimmerElementType.gap, width: 20 },
                { type: ShimmerElementType.line, width: 50, height: 8 },
                { type: ShimmerElementType.gap, width: 20 },
                { type: ShimmerElementType.line, width: 50, height: 8 },
                { type: ShimmerElementType.gap, width: '100%' },
              ]} />
            ))}
          </>
        }
      </Section>

      <ClassDatasetSelector
        classId={classId}
        datasetId={datasetId}
        classes={currentItem ? currentItem.classes : null}
        datasets={currentClass ? currentClass.datasets : null}
        isLoadingClass={isLoadingClass}
        isLoadingItem={isLoadingItem}
        standard={standard}
        version={version}
        getLink={getLinkFunc}
      />

      {undefined !== isLoadingClass && (
        <ClassDatasetTabs
          currentClass={currentClass}
          currentDataset={currentDataset}
          isLoadingClass={isLoadingClass}
          isLoadingDataset={isLoadingDataset}
          selectedPivotKey={selectedMetaPivotKey}
          setSelectedPivotKey={setSelectedMetaPivotKey}
          isLoadingSections={isLoadingSections}
          sections={sections}
          standardType={standard}
          version={version}
          standardSubtype={productSubtype}
        />
      )}

      {undefined !== isLoadingClass && (
        <VariablesTabs
          isIG={true}
          currentClass={currentClass}
          currentDataset={currentDataset}
          isLoadingClass={isLoadingClass}
          isLoadingDataset={isLoadingDataset}
          selectedPivotKey={selectedListPivotKey}
          setSelectedPivotKey={setSelectedListPivotKey}
        />
      )}

    </>
  );
}

export default SDTMIGComponent;
