/**
 * Data service for CT requests.
 * @packageDocumentation 
 * 
 * @module DataService-CT
 */
import { ICT, ICTCodelist } from "../../models/ICT";
import { ILink } from "../../models/ILink";
import { isAfter, lightFormat } from 'date-fns';
import { BaseDataService } from '../BaseDataService';
import { MISSING_RESOURCE_ERROR } from '../DataService';

export class CT extends BaseDataService {

  /**
   * Get all Controlled Terminology (CT) Packages
   */
  private _getPackageLinks = async (): Promise<ILink[]> => {
    const response = await this.get<any>(`/api/mdr/ct/packages`);
    return response._links.packages;
  }

  private _getPackagesByVersion = async (packageLinks: ILink[], version: string, expandAll: boolean = false): Promise<ICT[]> => {
    // Filter packages to just the ones containing the specified version date
    const versionPackages = packageLinks.filter(p => p.href.indexOf(version) !== -1);

    // Fire off parallel API calls for the filtered package versions
    const params = `${expandAll ? '?expand=true' : ''}`
    const fullPackages = await Promise.all(versionPackages.map(async (p) => {
      try {
        let pkg = await this.get<ICT>(`/api${p.href}/codelists${params}`);
        return pkg;
      }
      catch (error) {
        return null;
      }
    }));

    return fullPackages.filter(p => p);
  }

  public getValidVersions = (packageLinks: ILink[]): string[] => {
    let hrefDateRegex = /([0-9]{4}-[0-9]{2}-[0-9]{2})$/gm;
    return packageLinks.map(pl => {
      hrefDateRegex.lastIndex = 0;
      const match = hrefDateRegex.exec(pl.href);
      return match && match.length > 0 ? match[0].trim().toLowerCase() : null;
    }).filter(p => p);
  }

  /**
   * Get Controlled Terminology (CT) Packages by Version (e.g. 2019-12-20)
   * @param version 
   */
  public getPackagesByVersion = async (version: string): Promise<ICT[]> => {
    if (!version) return null;
    // Get all packages
    const packageLinks = await this._getPackageLinks();

    // Check if the specified version is valid
    const loweredVersion = version.trim().toLowerCase();
    const validPackageVersions = this.getValidVersions(packageLinks);
    const isValidVersion = validPackageVersions.some(vp => vp === loweredVersion);

    if (!isValidVersion) throw new Error(`${MISSING_RESOURCE_ERROR} Invalid version`);

    // Get filtered packages by version
    return this._getPackagesByVersion(packageLinks, version);
  }

  /**
   * Get Controlled Terminology (CT) Packages for the current latest version
   */
  public getLatestPackages = async (productName: string): Promise<ICT[]> => {
    if (!productName) return null;
    // Get all packages
    const packages = await this._getPackageLinks();
    let hrefDateRegex = /([0-9]{4}-[0-9]{2}-[0-9]{2})$/gm;

    let latestDateForProduct = new Date(2000, 1, 1);
    for (let pkg of packages) {
      if (pkg.href.toLowerCase().trim().indexOf(productName.toLowerCase().trim()) > -1) {
        hrefDateRegex.lastIndex = 0;
        const match = hrefDateRegex.exec(pkg.href);
        if (match && match.length > 0) {
          let pkgDate = new Date(match[0]);
          if (isAfter(pkgDate, latestDateForProduct)) {
            latestDateForProduct = pkgDate;
          }
        }
      }
    }
    const latestDateUTC = new Date(latestDateForProduct.getTime() + latestDateForProduct.getTimezoneOffset() * 60 * 1000);
    const latestVersionForProduct = lightFormat(latestDateUTC, 'yyyy-MM-dd');

    return this._getPackagesByVersion(packages, latestVersionForProduct);
  }

  /**
   * Get Controlled Terminology (CT) Packages for the latest version of a codelist
   */
  public getLatestPackagesForCodelist = async (productName: string, codelistId: string): Promise<ICT[]> => {
    if (!productName || !codelistId) return null;
    const response = await this.get<any>(`/api/mdr/root/ct/${productName}/codelists/${codelistId}`);
    const versions: ILink[] = response._links.versions;

    const latestVersion = versions && versions.length > 0 ? versions[versions.length - 1] : null;
    const latestVersionPackageId = latestVersion ? latestVersion.href.replace('/mdr/ct/packages/', '').replace(`/codelists/${codelistId}`, '') : null;

    let hrefDateRegex = /([0-9]{4}-[0-9]{2}-[0-9]{2})$/gm;
    const dateMatch = hrefDateRegex.exec(latestVersionPackageId);
    const latestVersionDate = dateMatch && dateMatch.length > 0 ? dateMatch[0] : null;

    return this.getPackagesByVersion(latestVersionDate);
  }

  /**
   * Get Controlled Terminology Package by ID (e.g. adamct-2019-12-20)
   * @param packageId 
   */
  public getPackage = async (packageId: string): Promise<ICT> => {
    if (!packageId) return null;
    let pkg = await this.get<ICT>(`/api/mdr/ct/packages/${packageId}`);
    return pkg;
  }

  /**
   * Get Controlled Terminology Codelist by Package ID and Codelist ID (e.g. adamct-2019-12-20, C81223)
   * @param packageId 
   * @param codelistId 
   */
  public getCodelist = async (packageId: string, codelistId: string): Promise<ICTCodelist> => {
    if (!packageId || !codelistId) return null;
    const codelist = await this.get<ICTCodelist>(`/api/mdr/ct/packages/${packageId}/codelists/${codelistId}`);
    return codelist;
  }
}
