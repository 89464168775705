/**
 * Utility to parse a boolean-like string into a proper boolean type.
 * 
 * @param boolString boolean-like string
 */
export const parseBoolString = (boolString: string): boolean => {
  let bool = false;
  if (boolString) {
    switch (boolString.toLowerCase())
    {
      case "true":
      case "yes":
      case "1":
        bool = true;
    }
  }
  return bool;
}

export const capitalizeFirst = (word: string): string => {
  if (word.length < 1) {
    return word
  } else {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }
}