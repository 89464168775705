/**
 * Generic component for rendering text that has linebreaks (\n).
 * @packageDocumentation 
 */
import React from 'react';
import { FunctionComponent, Fragment } from 'react';

export interface IMultilineProps {
  text: string | any;
}

const Multiline: FunctionComponent<IMultilineProps> = ({ text }) => {
  return (<> {
    typeof text === "string" ?
      text.split('\n').map((item, key) => {
        return <Fragment key={key}>{item}<br /></Fragment>
      })
      : text
  } </>)
}

export default Multiline;