import React, { useContext, useEffect } from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { BASE_REDIRECT_URL } from '../../services/AuthService';
import { FORCE_LOGIN } from '../../config';
import AppContext from '../AppContext/AppContext';

const AuthenticationProvider: React.FC = ({ children }) => { 
  const { authService: auth } = useContext(AppContext);

  return (
    <MsalProvider instance={auth.msal}>
      {children}
    </MsalProvider>
  );
};

export const ResetPassword: React.FC = () => {
  const { authService: auth } = useContext(AppContext);
  const { inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === "none") {
      auth.resetPassword(BASE_REDIRECT_URL);
    }
  }, [ inProgress, auth ]);

  return null;
};

export const ForceLogin: React.FC = () => {
  const { authService: auth } = useContext(AppContext);
  const { inProgress } = useMsal();

  useEffect(() => {
    if (FORCE_LOGIN && !auth.isAuthenticated() && inProgress === "none") {
      auth.login();
    }
  }, [ inProgress, auth ]);

  return null;
};

export default AuthenticationProvider;