/**
 * Data service for SDTM requests.
 * @packageDocumentation 
 * 
 * @module DataService-SDTM
 */
import { BaseDataService } from '../BaseDataService';
import { ISDTMIG, ISDTMIGClass, ISDTMIGDataset } from '../../models/ISDTMIG';

export class SDTM extends BaseDataService {

  public getClassesByVersion = async (version: string): Promise<ISDTMIG> => {
    if (!version) return null;
    let result = await this.get<ISDTMIG>(`/api/mdr/sdtm/${version}/classes?expand=true`);
    return result;
  }

  public getDatasetsByVersion = async (version: string): Promise<ISDTMIG> => {
    if (!version) return null;
    let result = await this.get<ISDTMIG>(`/api/mdr/sdtm/${version}/datasets?expand=true`);
    return result;
  }

  public getClass = async (version: string, classId: string): Promise<ISDTMIGClass> => {
    if (!version || !classId) return null;
    let result = await this.get<ISDTMIGClass>(`/api/mdr/sdtm/${version}/classes/${classId}`);
    return result;
  }

  public getDataset = async (version: string, datasetId: string): Promise<ISDTMIGDataset> => {
    if (!version || !datasetId) return null;
    let result = await this.get<ISDTMIGDataset>(`/api/mdr/sdtm/${version}/datasets/${datasetId}`);
    return result;
  }

}
