import React from 'react';
import Section from '../../../components/Section/Section';
import { IQRSItem } from '../../../models/IQRS';
import { Stack } from '@fluentui/react/lib/Stack';
import { Label } from '@fluentui/react/lib/Label';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import { getLatestCTLink } from '../QRS';

export interface IItemSectionProps {
  isLoadingItem: boolean;
  currentItem: IQRSItem;
}

const ItemSection: React.FunctionComponent<IItemSectionProps> = ({
  isLoadingItem, currentItem
}) => {
  return (
    <>
      <Section>
        {!isLoadingItem && currentItem
          ? <>
            <Stack tokens={{ childrenGap: 20 }}>
              <Stack>
                <Label>Test Code</Label>
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                  {currentItem.itemCode} ({getLatestCTLink(currentItem._links.instrumentItemTESTCD)})
                </Stack>
              </Stack>
              <Stack>
                <Label>Test Name (Question Text)</Label>
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                  {currentItem.questionText} ({getLatestCTLink(currentItem._links.instrumentItemTEST)})
                </Stack>
              </Stack>
              <Stack>
                <Label>Label</Label>
                <span>{currentItem.label}</span>
              </Stack>
            </Stack>
          </>
          : <Shimmer />
        }
      </Section>

      {!isLoadingItem
        ? currentItem && 'responseGroup' in currentItem && 'responses' in currentItem.responseGroup
        && <>
          <Section>
            <Stack tokens={{ childrenGap: 10 }}>
              <h4>Response Group</h4>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack>
                  <Label>Name</Label>
                  <span>{currentItem.responseGroup.name}</span>
                </Stack>
                <Stack>
                  <Label>Label</Label>
                  <span>{currentItem.responseGroup.label}</span>
                </Stack>
              </Stack>
            </Stack>
          </Section>
        </>
        : <Section><Shimmer /></Section>
      }

    </>
  );
}

export default ItemSection;