import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../components/AppContext/AppContext";
import { getHrefId } from "../../../utils/LinkUtils";
import { IQRS } from "../../../models/IQRS";

export default function useQRSRouteObserver(
  instrumentId: string, version: string, itemId: string, currentItem: IQRS,
  getLink: (instrumentId: string, version: string, itemId?: string, keepCurrentParams?: boolean) => string
) {
  const history = useHistory();
  const { setError } = useContext(AppContext);

  const getDefaultLink = (): string | undefined => {
    let link: string = undefined;

    // do we have an item with items, but don't have a itemId in the url?
    if (currentItem && currentItem.items && !itemId) {

      // no itemId
      if (!itemId) {
        const newitemId = getHrefId(currentItem.items[0]._links.self);
        link = getLink(instrumentId, version, newitemId, true);
      }

      // have itemId
      else if (itemId) {
        link = getLink(instrumentId, version, itemId, true);
      }

    }

    return link;
  }

  // Watch Params, Update URL if needed
  useEffect(() => {
    if (currentItem) {
      try {
        const defaultLink = getDefaultLink();

        if (defaultLink) {
          history.replace(defaultLink);
        }
      }
      catch (error) {
        setError(`Unable to update QRS route`, error);
      }
    }
  }, [instrumentId, version, itemId, currentItem]);
}
