import { AccountInfo, Configuration, EventCallbackFunction, EventMessage, EventType, ILoggerCallback, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { hideApp } from "../App";
import { AUTHENTICATION, B2C_TOKEN_SCOPES, PUBLIC_URL } from "../config";
import CacheService from "./CacheService";
import { logError, logInfo, logVerbose, logWarning } from "./TelemetryService";

const LOGIN_SCOPES = [
  ...B2C_TOKEN_SCOPES,
  'offline_access' 
  // required to obtain a refresh token with B2C -> https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1999
  // with offline_access, we get a 14-day refresh token (not recommended for production)
  // without offline_access, we should get a 24-hour refresh token, however we don't receive a refresh token at all which causes the user to re-auth every 60 mins
  // this may be fixed when all non-SPA redirectUri types are removed from the App Registration per linked issue
];

const B2C_TENANT_URL = `${AUTHENTICATION.tenant}.b2clogin.com`;

export const BASE_REDIRECT_URL = `${window.location.origin}${PUBLIC_URL}/`;

const LAST_ACTION_STORAGE_KEY = '##AUTH_LAST_ACTION##';

enum LastAction {
  PasswordReset, EditProfile, Logout
}

export class AuthService {
  private _msal: PublicClientApplication;
  private _passwordResetTriggered: boolean = false;

  constructor() {
    this._msal = this._initPublicClient();
  }

  public get msal(): PublicClientApplication { 
    return this._msal;
  }

  private _initPublicClient = (): PublicClientApplication => {
    const msalConfig: Configuration = {
      auth: {
        authority: AUTHENTICATION.b2cPolicies.authorities.signUpSignIn.authority,
        clientId: AUTHENTICATION.clientId,
        knownAuthorities: [ `${AUTHENTICATION.tenant}.b2clogin.com` ],
        postLogoutRedirectUri: BASE_REDIRECT_URL,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
      },
      system: {
        loggerOptions: {
          loggerCallback: this._authLoggerCallback,
          logLevel: LogLevel.Verbose,
          piiLoggingEnabled: false
        }
      }
    };
  
    const msal = new PublicClientApplication(msalConfig);  
    msal.addEventCallback(this._authEventCallback);
    return msal;
  }

  private _authLoggerCallback: ILoggerCallback = (level: LogLevel, message: string, containsPii: boolean): void => {
    switch (level) {	
      case LogLevel.Error: logError(message); return;	
      case LogLevel.Warning: logWarning(message); return;	
      case LogLevel.Info:	logInfo(message); return;	
      case LogLevel.Verbose: logVerbose(message); return;	
    }
  };

  private _authEventCallback: EventCallbackFunction = (message: EventMessage): void => {
    // user cancelled B2C login, password reset, or edit profile -> clear last action
    if (this._isErrorCode(message, 'AADB2C90091')) { 
      this._setLastAction(null);
      this.hideApp();
      // redirect to login page
      this._msal.loginRedirect({
        authority:
          AUTHENTICATION.b2cPolicies.authorities.signUpSignIn.authority,
        scopes: LOGIN_SCOPES,
        redirectUri: BASE_REDIRECT_URL
      });
    }
    // user clicked forgot password -> trigger password reset user flow
    else if (this._isErrorCode(message, 'AADB2C90118')) { 
      this.resetPassword();
    }
    // user successfully reset password -> trigger alert and logout so user can login with new password
    else if (this._getLastAction() === LastAction.PasswordReset && message.eventType === EventType.LOGIN_SUCCESS) {
      // Hide the web app to prevent it from flashing to the user before the async logout action fires
      hideApp();
      alert("Password has been reset successfully. \nPlease sign-in with your new password.");
      this.logout();
    }
  };

  private _isErrorCode = (message: EventMessage, errorCode: string): boolean => {
    const errorMessage = message?.error?.message;
    return typeof(errorMessage) === "string" && errorMessage.indexOf(errorCode) !== -1;
  }

  public getAccount = (): AccountInfo => {
    return this._msal.getAllAccounts().find(a => a.environment === B2C_TENANT_URL);
  };


  public isAuthenticated = (): boolean => !!this.getAccount();

  public login = async (redirectStartPage?: string): Promise<void> => {
    // Prevent login if the user just triggered the forgot password flow
    if (this._passwordResetTriggered) {
      return;
    }
    // Prevent login if we see the last action was password reset, but then clear the last action to prevent the user from getting locked out (edge case)
    if (this._getLastAction() === LastAction.PasswordReset) {
      this._setLastAction(null);
      return;
    }
    // handle auth redired/do all initial setup for msal
    this._msal.handleRedirectPromise().then(() =>{
      // Check if user signed in
      const account = this.getAccount();
      if(!account){
        // redirect anonymous user to login page
        this._msal.loginRedirect({
          authority: AUTHENTICATION.b2cPolicies.authorities.signUpSignIn.authority,
          scopes: LOGIN_SCOPES,
          redirectUri: BASE_REDIRECT_URL,
          redirectStartPage,
        });
      }
    }).catch(err=>{
      // TODO: Handle errors
      console.log(err);
    });
  };

  public logout = async (): Promise<void> => {
    let account = null;
    try {
      this._setLastAction(LastAction.Logout);
      await CacheService.clear();
      account = this.getAccount();
    }
    catch (error) {
      logWarning(`An error occurred while clearing browser cache on logout. ${error.message}`);
    }
    finally {
      this._msal.logout(account ?? { account });
    }
  };

  public resetPassword = async (redirectStartPage?: string): Promise<void> => {
    this._setLastAction(LastAction.PasswordReset);
    this._passwordResetTriggered = true;
    return await this._msal.loginRedirect({
      authority: AUTHENTICATION.b2cPolicies.authorities.forgotPassword.authority,
      scopes: LOGIN_SCOPES,
      redirectUri: BASE_REDIRECT_URL,
      redirectStartPage
    });
  };

  public editProfile = async (): Promise<void> => {
    this._setLastAction(LastAction.EditProfile);
    return await this._msal.loginRedirect({
      authority: AUTHENTICATION.b2cPolicies.authorities.editProfile.authority,
      scopes: LOGIN_SCOPES,
    });
  };

  public getToken = async (): Promise<string> => {
    let token = null;
    const account = this.getAccount();

    try {    
      if (account) {
        try {
          // First Attempt: Silent Token Acquisition
          const silentTokenResult = await this._msal.acquireTokenSilent({ 
            scopes: LOGIN_SCOPES, 
            account 
          });
          if (silentTokenResult && silentTokenResult.accessToken) {
            token = silentTokenResult.accessToken;
          }
        } 
        catch (error) {
          logWarning(`Silent token acquisition failed. Attempt to acquire token via redirect. Message: ${error?.message ?? 'None'}`);
          // Second Attempt: Redirect Token Acquisition 
          await this._msal.acquireTokenRedirect({ 
            scopes: LOGIN_SCOPES, 
            account 
          });
        }
      }
      else {
        logWarning(`No MSAL accounts found for ${B2C_TENANT_URL}. Triggering login.`);
        await this.login();
      }
    }
    catch (error) {
      logError("Unable to acquire access token", true, error);
    }

    return token;
  }

  private _setLastAction = (lastAction: LastAction): void => {
    sessionStorage.setItem(LAST_ACTION_STORAGE_KEY, LastAction[lastAction]);
  }

  private _getLastAction = (): LastAction => {
    return LastAction[sessionStorage.getItem(LAST_ACTION_STORAGE_KEY)];
  }

  hideApp = (): void => {
    document.body.setAttribute("hidden", "true");
  };;
}