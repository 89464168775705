/**
 * Generic layout component that renders a section with a title.
 * @packageDocumentation 
 */
import React from 'react';
import './Section.scss';
import { Stack } from '@fluentui/react/lib/Stack';

export interface ISectionProps {
  pageTitle?: string;
  className?: string;
  link?: string;
}

const Section: React.FunctionComponent<ISectionProps> = ({ pageTitle, className, link, children }) => {
  return (
    <section className={`section${className ? ` ${className}` : ''}`}>
      <Stack tokens={{ childrenGap: 10 }}>
        {link && pageTitle ?
          (<a target="_blank" href={link}><h1>{pageTitle}</h1></a>) :
          (pageTitle && <h1>{pageTitle}</h1>)
        }
        {children && <div className="section-content">{children}</div>}
      </Stack>
    </section>
  )
}

export default Section;