import React from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { Stack } from '@fluentui/react/lib/Stack';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { IItem } from './List';
import { Label } from '@fluentui/react/lib/Label';

export interface IListPanelProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  item: IItem;
  columns: IColumn[];
}

const ListPanel: React.FunctionComponent<IListPanelProps> = ({ isOpen, onClose, title, item, columns }) => {
  const emptyValue = '- -';
  return (<>
    <Panel
      headerText={title}
      isOpen={isOpen}
      isBlocking={false}
      closeButtonAriaLabel="Close"
      onDismiss={onClose}
      type={PanelType.custom}
      customWidth="420px"
      className="list-panel"
      layerProps={{ 
        hostId: "main",
        className: "list-panel-layer"
      }}
      styles={{
        main: "list-panel-main"
      }}
    >
      {item && columns && columns.length > 0 && <>
        <Stack className="list-panel-inner" tokens={{ childrenGap: 20 }}>
          {columns.map(col => {
            const value = item[col.fieldName];         
            return (
              <Stack key={col.key}>
                <Label>{col.name}</Label>
                <span>
                  {col.onRender 
                      ? col.onRender(item, 0, col) || emptyValue 
                      : value || emptyValue
                  }
                </span>
              </Stack>
            )
          })}
        </Stack>
      </>}
    </Panel>
  </>);
}

export default ListPanel;