import { SUPPORTED_ACCEPT_TYPES } from "../services/DataService";

export interface IExportType {
  key: string;
  supportedAcceptType: SUPPORTED_ACCEPT_TYPES;
  text: string;
}

export const ExportTypes: {
  [key: string]: IExportType;
} = {
  csv: {
    key: "csv",
    supportedAcceptType: SUPPORTED_ACCEPT_TYPES.CSV,
    text: "Comma-Separated Values (CSV)",
  },
  xlsx: {
    key: "xlsx",
    supportedAcceptType: SUPPORTED_ACCEPT_TYPES.EXCEL,
    text: "Microsoft® Excel® (XLSX)",
  },
  diff: {
    key: "diff",
    supportedAcceptType: SUPPORTED_ACCEPT_TYPES.EXCEL,
    text: "Diff Report in Microsoft® Excel® (XLSX)",
  },
  direct: {
    key: "direct",
    supportedAcceptType: SUPPORTED_ACCEPT_TYPES.GZ,
    text: "Supplementary Files (GZ)",
  },
};

export interface IExportConfig {
  exportType: IExportType;
  requiredVariables: string[];
  request: string;
  exportFileName?: string;
}
