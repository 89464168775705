import React from 'react';
import Section from '../../../components/Section/Section';
import { Link } from 'react-router-dom';
import { getHrefId } from '../../../utils/LinkUtils';
import { Stack } from '@fluentui/react/lib/Stack';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { ISDTMClass, ISDTMDataset } from '../../../models/ISDTM';

export interface IClassDatasetSelectorProps { 
  standard: string;
  version: string;
  classId: string;
  datasetId: string;
  isLoadingItem: boolean;
  isLoadingClass: boolean;
  classes: ISDTMClass[];
  datasets: ISDTMDataset[];
  getLink: (standard: string, version: string, classId?: string, datasetId?: string) => string;
}

const ClassDatasetSelector: React.FunctionComponent<IClassDatasetSelectorProps> = ({ 
  standard, version, classId, datasetId, isLoadingClass, isLoadingItem, classes, datasets, getLink
}) => {
  return (
    <Section>
      <Stack tokens={{ childrenGap: 10 }}>
        {/* Buttons for Classes */}
        <h4>Classes</h4>
        <div>
          {!isLoadingItem && classes
            ? classes.map((currentClass, idx) => {
                const currentClassId = getHrefId(currentClass._links.self);
                const link = getLink(standard, version, currentClassId);
                const isSelected = classId && classId.trim().toLowerCase() === currentClassId.trim().toLowerCase();
                return (
                  <Link key={idx} to={link}>
                    <DefaultButton text={currentClass.name} className={isSelected ? 'is-selected' : ''} />
                  </Link>
                );
              })
            : <Shimmer />
          }
        </div>

        {/*  Buttons for Data Sets */}
        {!isLoadingClass && datasets && <>
          <div>
            <h4>Data Sets</h4>
            {datasets.map((currentDataset, idx) => {
                const currentDatasetId = getHrefId(currentDataset._links.self);
                const link = getLink(standard, version, classId, currentDatasetId);
                const isSelected = datasetId && datasetId.trim().toLowerCase() === currentDatasetId.trim().toLowerCase();
                return (
                  <Link key={idx} to={link}>
                    <DefaultButton text={currentDataset.name} className={isSelected ? 'is-selected' : ''} />
                  </Link>
                );
            })}
          </div>
        </>}
      </Stack>
    </Section>
  );
}

export default ClassDatasetSelector;
