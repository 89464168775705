/**
 * Service to facilitate logging across the solution. Sends telemetry data to Azure Application Insights.
 * @packageDocumentation 
 * 
 * @module TelemetryService
 */
import { ApplicationInsights, ITelemetryItem, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { APP_INSIGHTS_KEY, DEBUG_LOGGING_ENABLED } from '../config';

let reactPlugin: ReactPlugin = null;
let appInsights: ApplicationInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

  const telemetryInitializer = (telemetryItem: ITelemetryItem): void => {
    telemetryItem.tags["ai.cloud.role"] = 'cdisc-library-browser';
  }

  /**
   * Initialize the Application Insights class
   * @return {void}
   */
  const initialize = () => {
    if (!APP_INSIGHTS_KEY) {
      console.log('App Insights Instrumentation key not provided. No Application Insights telemetry will be logged.');
      return;
    }

    reactPlugin = new ReactPlugin();
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: APP_INSIGHTS_KEY,
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        enableDebugExceptions: DEBUG_LOGGING_ENABLED      
      }
    });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer(telemetryInitializer);
  };

  return { reactPlugin, appInsights, initialize };
};

export const logVerbose = (message: string) => {
  if (!appInsights || !DEBUG_LOGGING_ENABLED) return;

  appInsights.trackTrace({
    message,
    severityLevel: SeverityLevel.Verbose,
  });

  if (DEBUG_LOGGING_ENABLED) console.debug(`[TelemetryService] ${message}`);
};

export const logInfo = (message: string) => {
  if (!appInsights || !DEBUG_LOGGING_ENABLED) return;
  
  appInsights.trackTrace({
    message,
    severityLevel: SeverityLevel.Information,
  });

  if (DEBUG_LOGGING_ENABLED) console.info(`[TelemetryService] ${message}`);
};

export const logWarning = (message: string) => {
  if (!appInsights) return;
  
  appInsights.trackTrace({
    message,
    severityLevel: SeverityLevel.Warning,
  });

  if (DEBUG_LOGGING_ENABLED) console.warn(`[TelemetryService] ${message}`);
};

export const logError = (message: string, isCritical: boolean = false, exception?: Error) => {
  if (!appInsights) return;
  
  const severityLevel = isCritical ? SeverityLevel.Critical : SeverityLevel.Error;
  appInsights.trackTrace({
    message,
    severityLevel,
  });
  appInsights.trackException({
    properties: {
      message
    },
    exception: exception || new Error(message), 
    severityLevel,
  });
  
  if (DEBUG_LOGGING_ENABLED) console.error(`[TelemetryService] [isCritical=${isCritical}] ${message}`, exception);
};

export const ai = createTelemetryService();
export const getAppInsights = (): ApplicationInsights => appInsights;