/**
 * Data service for QRS requests.
 * @packageDocumentation 
 * 
 * @module DataService-QRS
 */
import { BaseDataService } from '../BaseDataService';
import { IQRS, IQRSItem } from '../../models/IQRS';

export class QRS extends BaseDataService {

  public getItemsByVersion = async (instrumentId: string, version: string): Promise<IQRS> => {
    if (!instrumentId || !version) return null;
    let result = await this.get<IQRS>(`/api/mdr/qrs/instruments/${instrumentId}/versions/${version}/items?expand=true`);
    return result;
  }

  public getItem = async (instrumentId: string, version: string, itemId: string): Promise<IQRSItem> => {
    if (!instrumentId || !version || !itemId) return null;
    let result = await this.get<IQRSItem>(`/api/mdr/qrs/instruments/${instrumentId}/versions/${version}/items/${itemId}`);
    return result;
  }

}
