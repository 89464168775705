import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../components/AppContext/AppContext";
import { ICT } from "../../../models/ICT";
import { getPackageHrefId, isLatestVersion } from "../CT";

export default function useCTRouteObserver(
  version: string, packageId: string, codelistId: string, termId: string, isLoadingPackages: boolean, packages: ICT[],
  getLink: (version: string, packageId?: string, codelistId?: string, termId?: string, keepCurrentParams?) => string
) {
  const history = useHistory();
  const { setError } = useContext(AppContext);

  const getDefaultLink = (): string | undefined => {
    let link: string = undefined;
  
    // request latest package for version
    if (isLatestVersion(version)) {
      const targetLatestPackage = packages.find(p => getPackageHrefId(p._links.self).indexOf(packageId) > -1);
      const newPackageId = targetLatestPackage ? getPackageHrefId(targetLatestPackage._links.self) : '';
      const newVersion = targetLatestPackage ? targetLatestPackage.version : '';
      link = getLink(newVersion, newPackageId, codelistId, termId, true);
    }
    // no packageId
    else if (!packageId) {
      const newPackageId = getPackageHrefId(packages[0]._links.self);
      link = getLink(version, newPackageId, codelistId, termId, true);
    }
  
    return link;
  }

  // Watch Params, Update URL if needed
  useEffect(() => {
    if (!isLoadingPackages && packages && packages.length > 0) {
      try {
        const defaultLink = getDefaultLink();
        
        if (defaultLink) {
          history.replace(defaultLink);
        }
      }
      catch (error) {
        setError(`Unable to update CT Package route`, error);
      }
    }
  }, [version, packageId, codelistId, termId, isLoadingPackages, packages]);
}
