/**
 * App base. Manage routes including invoking the primary hash router. Prompts user login.
 * @packageDocumentation 
 */
import React, { useContext } from 'react';
import Layout from './components/Layout/Layout';
import { HashRouter, BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import RouteManager from './routes/RouteManager';
import { PUBLIC_URL } from './config';
import { ForceLogin, ResetPassword } from './components/AuthenticationProvider/AuthenticationProvider';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import ExceptionCatcher from './components/ExceptionCatcher/ExceptionCatcher';
import AppContext from './components/AppContext/AppContext';

const App = () => {
  const { setError } = useContext(AppContext);

  

  return (
    <ExceptionCatcher setError={setError}>
      <BrowserRouter>
        <Switch>
          {/* Redirect / to /browser/ */}
          <Redirect exact strict from='/' to={`${PUBLIC_URL}/`} />
          {/* Redirect /browser to /browser/ (ensure trailing slash) */}
          <Redirect exact strict from={PUBLIC_URL} to={`${PUBLIC_URL}/`} />
          {/* Trigger Password Reset at /browser/password-reset */}
          <Route exact strict path={`${PUBLIC_URL}/password-reset`} component={ResetPassword} />
          {/* Base App at /browser/ */}
          <Route exact path={`${PUBLIC_URL}/`}>
            {/* User not authenticated -> Prompt Login */}
            <UnauthenticatedTemplate>
              <ForceLogin />
            </UnauthenticatedTemplate>
            {/* User is authenticated -> Load App */}
            <AuthenticatedTemplate>
              {/* Hash Router at /browser/#/ */}
              <HashRouter>
                <Layout>
                  <RouteManager/>
                </Layout>
              </HashRouter>
            </AuthenticatedTemplate>
          </Route>
        </Switch>
      </BrowserRouter>
    </ExceptionCatcher>
  );
}


/**
 * Utility to hide the main web app
 */
  export const hideApp = (): void => {
  if (document.body.className.indexOf('hidden') === -1) {
    document.body.className = `${document.body.className} hidden`;
  }
}

/**
 * Utility to un-hide the main web app
 */
export const showApp = (): void => {
  document.body.className = document.body.className.replace(/hidden/g, '').trim();
}

export default App;
