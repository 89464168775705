/**
 * Generic React hook for making search requests.
 * @packageDocumentation 
 * 
 * @module useSearchResults
 */
import { useContext, useEffect } from "react";
import useAsyncData from "./useAsyncData";
import AppContext from "../components/AppContext/AppContext";
import { ISearchParams, ISearchResponse } from "../models/ISearch";

export type SearchResultsData = {
  isLoading: boolean;
  response: ISearchResponse;
}

export default function useSearchResults(searchParams: ISearchParams): SearchResultsData {
  const { setError, dataService } = useContext(AppContext);

  // Fetch Results
  const { 
    isLoading, 
    data: response,
    error,
    replace
  } = useAsyncData<ISearchResponse>(null, dataService.search.search, [searchParams]);

  // Notify UI of Any Errors
  useEffect(() => {
    let responseError = response && 'error' in response && 'message' in response['error'] ? response['error']['message'] : null;
    if (error || responseError) {
      replace(null);
      setError(`Unable to load search results`, error || responseError);
    }
  });

  return {
    isLoading, response
  };
}