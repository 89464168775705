import React from 'react';
import { logError } from '../../services/TelemetryService';

export interface IExceptionCatcherProps {
  setError: (title: string, message: string, isUncaught?: boolean) => void;
}

class ExceptionCatcher extends React.Component<IExceptionCatcherProps, {}> {

  public componentDidCatch(error, info) {
    logError(`Unhandled application exception: ${error}`, true, error);
    this.props.setError(`Unhandled application exception.`,`${error}`, true);
  }

  public render() {
    return this.props.children;
  }
  
}

export default ExceptionCatcher;