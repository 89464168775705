import React, { useEffect, useState } from 'react';
import List, { IItem } from '../../../components/List/List';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { ICDASHField } from '../../../models/ICDASH';
import { getHrefId } from '../../../utils/LinkUtils';
import { Link } from 'react-router-dom';
import { parseBoolString } from '../../../utils/DataUtils';
import { onRenderCodelistLinks, parseCodelistLinks } from '../../../components/Link/Link';

export interface IFieldsProps {
  fields: ICDASHField[];
  isLoading: boolean;
  isDomain?: boolean;
}

export interface ICDASHFieldVM extends ICDASHField {
  mappingTargetsLink: string;
  codelistLinks: string;
  implementsLink: string;
}

export const onRenderMappingTargetsLink = (item?: ICDASHFieldVM, index?: number, column?: IColumn) => {
  if (item && item.mappingTargetsLink) {
    const mappingTargetsLinks: string[] = JSON.parse(item.mappingTargetsLink);
    return mappingTargetsLinks.map((mtl, idx) => {
      const variableId = getHrefId(mtl);
      const linkPath = mtl.replace('/variables/', '?filterFields=name&filterIsExact=true&filter=');
      return (<>
        {idx > 0 ? '; ' : ''}
        <Link key={variableId} to={linkPath}>{variableId}</Link>
      </>);
    })
  }
}


const Fields: React.FunctionComponent<IFieldsProps> = ({ fields, isLoading, isDomain = false }) => {
  const [ items, setItems ] = useState<any[]>([]);
  
  let columns: IColumn[] = [
    { key: "ordinal", fieldName: "ordinal", name: "Ordinal", minWidth: 70, maxWidth: 70, isResizable: true, isSorted: true, isSortedDescending: false, isMultiline: true },
    { key: "name", fieldName: "name", name: "Name", minWidth: 70, maxWidth: 100, isResizable: true, isMultiline: true },
    { key: "label", fieldName: "label", name: "Label", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "definition", fieldName: "definition", name: "Definition", minWidth: 100, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "questionText", fieldName: "questionText", name: "Question Text", minWidth: 100, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "prompt", fieldName: "prompt", name: "Prompt", minWidth: 100, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "simpleDatatype", fieldName: "simpleDatatype", name: "Data Type", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "implementationNotes", fieldName: "implementationNotes", name: "Implementation Notes", minWidth: 200, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "mappingInstructions", fieldName: "mappingInstructions", name: "Mapping Instructions", minWidth: 200, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "mappingTargetsLink", fieldName: "mappingTargetsLink", name: "Mapping Targets", minWidth: 120, maxWidth: 200, isResizable: true, isMultiline: true, onRender: onRenderMappingTargetsLink },
    { key: "codelistLink", fieldName: "codelistLink", name: "Code List", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true, onRender: onRenderCodelistLinks },
  ];

  if (isDomain) {
    const domainSpecificColumn: IColumn = { 
      key: "domainSpecific", fieldName: "domainSpecific", name: "Domain Specific", minWidth: 110, maxWidth: 110, isResizable: true, isMultiline: true 
    }
    //Insert "Domain Specific" after "Ordinal" when rendering Domain fields
    columns.splice(1, 0, domainSpecificColumn);
  }

  const mapItemsWithDefaults = (flds: ICDASHField[]): IItem[] => {
    return flds.map<IItem>(f => {
      const codelistLinks = parseCodelistLinks(f._links.codelist);
      const mappingTargets = [
        ...(f._links.sdtmClassMappingTargets ?? []),
        ...(f._links.sdtmDatasetMappingTargets ?? []),
        ...(f._links.sdtmigDatasetMappingTargets ?? []),
      ];
      const mappingTargetsLink = mappingTargets
        ? JSON.stringify(mappingTargets.map((cl) => cl.href))
        : "";

      return {
        key: f.name,
        ordinal: f.ordinal ? parseInt(f.ordinal) : 0,
        domainSpecific: parseBoolString(f.domainSpecific) ? 'Yes' : 'No',
        name: f.name,
        label: f.label,
        definition: f.definition,
        questionText: f.questionText,
        prompt: f.prompt,
        simpleDatatype: f.simpleDatatype,
        implementationNotes: f.implementationNotes,
        mappingInstructions: f.mappingInstructions,
        mappingTargetsLink,
        codelistLinks,
      };
    })
  }

  useEffect(() => {
    if (fields) {
      setItems(mapItemsWithDefaults(fields));
    }
  }, [fields]);

  return (
    <List 
      items={items}
      columns={columns} 
      isLoading={isLoading} 
    />
  );
}

export default Fields;