import React, { useEffect, useState } from 'react';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import Section from '../../components/Section/Section';
import useIGDocuments from '../../hooks/useIGDocuments';
import './ImplementationGuideDocument.scss';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { PivotKey } from '../../models/PivotKey';
import { capitalizeFirst } from '../../utils/DataUtils';

export interface IImplementationGuideDocumentProps {
  sectionName: string;
  standardType: string;
  version: string;
  documentStructure?: string;
  useCase?: string;
  standardSubtype?: string;
}

const ImplementationGuideDocument: React.FunctionComponent<IImplementationGuideDocumentProps> = (
  { sectionName, standardType, version, documentStructure, useCase, standardSubtype}) => {
  
  const sortByTitle =  (a,b) => {return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)};
  // Fetch documents
  const {
    isLoadingDocument, response
  } = useIGDocuments(standardType, version, sectionName, documentStructure, useCase, standardSubtype);
  const [selectedDocument, setSelectedDocument] = useState<PivotKey>(PivotKey.Class);
  return (
    <Section className="ig-doc">
      {sectionName && !isLoadingDocument && response
        ? <Pivot selectedKey={selectedDocument} onLinkClick={(pivotItem: PivotItem) => setSelectedDocument(pivotItem.props.itemKey as PivotKey)}>
                    {response.documents.sort(sortByTitle).map((document, idx) => (
                    <PivotItem key={idx} itemKey={document.title} headerText={capitalizeFirst(document.title)}>
                        { <div dangerouslySetInnerHTML={{__html: document.html }} /> }
                      
                    </PivotItem>))}
          </Pivot> :
        <Shimmer styles={{ root: { minHeight: '35px' }}} shimmerElements={[
            { type: ShimmerElementType.line, width: 400, height: 8 },
            { type: ShimmerElementType.gap, width: '100%' }
          ]} />
      }
    </Section>
  );
}

export default ImplementationGuideDocument;
