import React, { useEffect, useState } from 'react';
import List, { IItem } from '../../../components/List/List';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { ISDTMVariable } from '../../../models/ISDTM';
import { getHrefId } from '../../../utils/LinkUtils';
import { Link } from 'react-router-dom';

export interface IVariablesProps {
  variables: ISDTMVariable[];
  isLoading: boolean;
}

export interface ISDTMVariableVM extends ISDTMVariable {
  qualifiesVariablesLink: string;
}

const Variables: React.FunctionComponent<IVariablesProps> = ({ variables, isLoading }) => {
  const [ items, setItems ] = useState<any[]>([]);
 
  const onRenderQualifiesVariablesLink = (item?: ISDTMVariableVM, index?: number, column?: IColumn) => {
    if (item && item.qualifiesVariablesLink) {
      const qualifiesVariablesLinks: string[] = JSON.parse(item.qualifiesVariablesLink);
      return qualifiesVariablesLinks.map((qv, idx) => {
        const linkId = getHrefId(qv);
        const linkPath = qv.replace('/variables/', '?filterFields=name&filterIsExact=true&filter=');
        return (<>
          {idx > 0 ? '; ' : ''}
          <Link key={`${item.name}.${linkId}.${idx}`} to={linkPath}>{linkId}</Link>
        </>);
      })
    }
  }

  let columns: IColumn[] = [
    { key: "ordinal", fieldName: "ordinal", name: "Ordinal", minWidth: 70, maxWidth: 70, isResizable: true, isSorted: true, isSortedDescending: false, isMultiline: true },
    { key: "name", fieldName: "name", name: "Name", minWidth: 100, maxWidth: 100, isResizable: true, isMultiline: true },
    { key: "label", fieldName: "label", name: "Label", minWidth: 100, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "simpleDatatype", fieldName: "simpleDatatype", name: "Data Type", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "role", fieldName: "role", name: "Role", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "qualifiesVariablesLink", fieldName: "qualifiesVariablesLink", name: "Qualifies Variables", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true, onRender: onRenderQualifiesVariablesLink },
    { key: "describedValueDomain", fieldName: "describedValueDomain", name: "Described Value Domain", minWidth: 160, maxWidth: 160, isResizable: true, isMultiline: true },
  ];

  let additionalColumns: IColumn[] = [
    { key: "description", fieldName: "description", name: "Description", minWidth: 300, isResizable: true, isMultiline: true },
    { key: "notes", fieldName: "notes", name: "Notes", minWidth: 200, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "usageRestrictions", fieldName: "usageRestrictions", name: "Usage Restrictions", minWidth: 170, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "definition", fieldName: "definition", name: "Definition", minWidth: 200, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "variableCcode", fieldName: "variableCcode", name: "Variable C-Code", minWidth: 70, maxWidth: 120, isResizable: true, isMultiline: true },
    { key: "examples", fieldName: "examples", name: "Examples", minWidth: 160, maxWidth: 170, isResizable: true, isMultiline: true },
  ]

  const varsHaveKey = (vars: ISDTMVariable[], key: string): boolean => {
    for (var variable of variables) {
      if (variable[key] !== undefined) {
        return true
      }
    }
    return false
  }

  for (var column of additionalColumns) {
    let field = column.fieldName
    if (varsHaveKey(variables, field)) {
      columns.push(column)
    }
  }

  const mapItemsWithDefaults = (vars: ISDTMVariable[]): IItem[] => {
    return vars.map<IItem>(v => {

      const qualifiesVariablesLink = v._links.qualifiesVariables ? JSON.stringify(v._links.qualifiesVariables.map(qv => qv.href)) : '';

      return {
        key: v.name,
        ordinal: v.ordinal ? parseInt(v.ordinal) : 0,
        name: v.name,
        label: v.label,
        description: v.description,
        simpleDatatype: v.simpleDatatype,
        role: v.role,
        describedValueDomain: v.describedValueDomain,
        notes: v.notes,
        usageRestrictions: v.usageRestrictions,
        variableCcode: v.variableCcode,
        examples: v.examples,
        definition: v.definition,
        qualifiesVariablesLink
      };
    })
  }

  useEffect(() => {
    if (variables) {
      setItems(mapItemsWithDefaults(variables));
    }
  }, [variables]);

  return (
    <List 
      items={items}
      columns={columns} 
      isLoading={isLoading} 
    />
  );
}

export default Variables;
