/**
 * Most common export button component containing Excel, CSV, and diff file options.
 * @packageDocumentation
 */
import React, { useMemo } from "react";
import { ExportTypes, IExportConfig } from "../../models/IExportConfig";
import ExportButton from "./ExportButton";

const StandardExportButton = ({
  pageTitle,
  standard,
  version,
  productSubtype,
}: {
  pageTitle: string;
  standard: string;
  version: string;
  productSubtype?: string;
}) => {
  const exportConfig = useMemo<IExportConfig[]>(
    () => [
      {
        exportType: ExportTypes.xlsx,
        requiredVariables: [standard, version],
        request: `/api/mdr/${
          productSubtype ? "integrated/" : ""
        }${standard}/${version}${productSubtype ? `/${productSubtype}` : ""}`,
        exportFileName: `${pageTitle.replace(/\s/g, "_")}.xlsx`,
      },
      {
        exportType: ExportTypes.csv,
        requiredVariables: [standard, version],
        request: `/api/mdr/${
          productSubtype ? "integrated/" : ""
        }${standard}/${version}${productSubtype ? `/${productSubtype}` : ""}`,
        exportFileName: `${pageTitle.replace(/\s/g, "_")}.csv`,
      },
      {
        exportType: ExportTypes.diff,
        requiredVariables: [standard, version],
        request: `/api/mdr/diff/${
          productSubtype ? "integrated/" : ""
        }${standard}/${version}${productSubtype ? `/${productSubtype}` : ""}`,
      },
    ],
    [standard, version, productSubtype, pageTitle]
  );
  return <ExportButton>{exportConfig}</ExportButton>;
};

export default StandardExportButton;
