import React from 'react';
import Section from '../../../components/Section/Section';
import { Link } from 'react-router-dom';
import { getHrefId } from '../../../utils/LinkUtils';
import { Stack } from '@fluentui/react/lib/Stack';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { IADAMVariableSet, IADAMDataStructure } from '../../../models/IADAM';

export interface IDataStructureVariableSetSelectorProps { 
  standard: string;
  version: string;
  dataStructureId: string;
  variableSetId: string;
  isLoadingItem: boolean;
  isLoadingDataStructure: boolean;
  dataStructures: IADAMDataStructure[];
  variableSets: IADAMVariableSet[];
  getLink: (standard: string, version: string, dataStructureId?: string, variableSetId?: string, keepCurrentParams?: boolean) => string;
}

const DataStructureVariableSetSelector: React.FunctionComponent<IDataStructureVariableSetSelectorProps> = ({ 
  standard, version, dataStructureId, variableSetId,isLoadingDataStructure, isLoadingItem, dataStructures, variableSets, getLink
}) => {
  return (
    <Section>
      <Stack tokens={{ childrenGap: 10 }}>
        {/* Buttons for Data Structures */}
        <h4>Data Structures</h4>
        <div>
          {!isLoadingItem 
            ? dataStructures 
              ? dataStructures.map((currentDataStructure, idx) => {
                const currentDataStructureId = getHrefId(currentDataStructure._links.self);
                const link = getLink(standard, version, currentDataStructureId);
                const isSelected = dataStructureId && dataStructureId.trim().toLowerCase() === currentDataStructureId.trim().toLowerCase();
                return (
                  <Link key={idx} to={link}>
                    <DefaultButton text={currentDataStructure.name} className={isSelected ? 'is-selected' : ''} />
                  </Link>
                );
              })
              : <Stack>None</Stack>
            : <Shimmer />
          }
        </div>

        {/*  Buttons for Variable Sets */}
        {!isLoadingDataStructure && variableSets && <>
          <div>
            <h4>Variable Sets</h4>
            {variableSets.map((currentVariableSet, idx) => {
                const currentVariableSetId = getHrefId(currentVariableSet._links.self);
                const link = getLink(standard, version, dataStructureId, currentVariableSetId);
                const isSelected = variableSetId && variableSetId.trim().toLowerCase() === currentVariableSetId.trim().toLowerCase();
                return (
                  <Link key={idx} to={link}>
                    <DefaultButton text={currentVariableSet.name} className={isSelected ? 'is-selected' : ''} />
                  </Link>
                );
            })}
          </div>
        </>}
      </Stack>
    </Section>
  );
}

export default DataStructureVariableSetSelector;
