import { ILink } from '../models/ILink.js';
import queryString from 'query-string';
import { PivotKey } from '../models/PivotKey';
import { ISearchResult } from '../models/ISearch';


/**
 * Utility to check for the `preferTab` query string param and parse it into a known enum type.
 * 
 * @param location pass in the current window.location
 */
export const getPreferredTab = (location: any): PivotKey => {
  let preferredTab: PivotKey = null;
  if (location) {
    const queryParams = queryString.parse(getQueryString(location));
    const preferTab = queryParams && queryParams.preferTab ? `${queryParams.preferTab}` : '';
    if (preferTab && PivotKey) {
      preferredTab = PivotKey[preferTab] || null;
    }
  }
  return preferredTab;
}

/**
 * Generic utility to get the URL query string. Supports hash routing query string params.
 * 
 * @param location pass in the current window.location
 */
export const getQueryString = (location: any): string => {
  let result: string = '';
  if (location) {
    try {
      if (location.search) result = location.search;
      else {
        const qmIndex = location.href.lastIndexOf('?');
        if (qmIndex > -1) {
          result = location.href.substr(qmIndex);
        }
      }
    }
    catch { /* Can't parse query string, let empty string be returned */ }
  }
  return result;
}

/**
 * Utility to parse a link to get the identifier following the final `/`.
 * 
 * @param link an `ILink` hateoas link object or href string
 */
export const getHrefId = (link: ILink | string): string => {
  let href = typeof link === 'object' ? link.href : link;
  return href ? href.substring(href.lastIndexOf('/') + 1) : '';
}

/**
 * Utility to parse a search result item into a SPA-routable URL.
 * 
 * Example: 
 *   Turn: /mdr/ct/2018-09-28/packages/sdtmct-2018-09-28/codelists/C115391/terms/C115808
 *   Into: /mdr/ct/2018-09-28/packages/sdtmct-2018-09-28/codelists/C115391?filterFields=conceptId&filterIsExact=true&filter=C115808
 * 
 * @param item search result item
 */
export const getUiHref = (item: ISearchResult): string => {
  if (!item) return "/";
  
  let newHref = item.href;
  const newHrefLowered = newHref.toLowerCase();

  // Turn: /mdr/ct/packages/sdtmct-2015-12-18/codelists/C115391
  // Into: /mdr/ct/2015-12-18/packages/sdtmct-2015-12-18/codelists/C115391
  if (newHrefLowered.startsWith('/mdr/ct/packages')) {
    const match = /([0-9]{4}-[0-9]{2}-[0-9]{2})/gm.exec(newHref);
    const dateVersion = match && match.length > 0 ? match[0].trim().toLowerCase() : "";
    newHref = newHref.replace('/mdr/ct/packages', `/mdr/ct/${dateVersion}/packages`);
  }

  // Turn: /mdr/ct/2018-09-28/packages/sdtmct-2018-09-28/codelists/C115391/terms/C115808
  // Into: /mdr/ct/2018-09-28/packages/sdtmct-2018-09-28/codelists/C115391?filterFields=conceptId&filterIsExact=true&filter=C115808
  if (newHrefLowered.indexOf('/terms/') > -1) {
    const [ parentHref, termId ] = newHref.split('/terms/');
    newHref = `${parentHref}?filterFields=conceptId&filterIsExact=true&filter=${termId}`;
  }

  // Turn: /mdr/cdashig/1-1-1/domains/DV/fields/STUDYID
  // Into: /mdr/cdashig/1-1-1/domains/DV?filterFields=name&filterIsExact=true&filter=STUDYID&preferTab=Domain
  if (newHrefLowered.indexOf('/domains/') > -1 && newHrefLowered.indexOf('/fields/') > -1) {
    const parentDomainLink = item.links.find(l => l.linkName === 'parentDomain');
    if (parentDomainLink) {
      newHref = `${parentDomainLink.href}?filterFields=name&filterIsExact=true&filter=${item.name}&preferTab=Domain`;
    }
  }

  // Turn: /mdr/cdash/1-1/classes/Events/fields/--YN
  // Into: /mdr/cdash/1-1/classes/Events?filterFields=name&filterIsExact=true&filter=--YN&preferTab=Class
  if (newHrefLowered.indexOf('/classes/') > -1 && newHrefLowered.indexOf('/fields/') > -1) {
    const parentClassLink = item.links.find(l => l.linkName === 'parentClass');
    if (parentClassLink) {
      newHref = `${parentClassLink.href}?filterFields=name&filterIsExact=true&filter=${item.name}&preferTab=Class`;
    }
  }

  // Turn: /mdr/sdtm/1-7/classes/GeneralObservations/variables/--DUR
  // Into: /mdr/sdtm/1-7/classes/GeneralObservations?filterFields=name&filterIsExact=true&filter=--DUR&preferTab=Class
  if (newHrefLowered.indexOf('/classes/') > -1 && newHrefLowered.indexOf('/variables/') > -1) {
    const parentClassLink = item.links.find(l => l.linkName === 'parentClass');
    if (parentClassLink) {
      newHref = `${parentClassLink.href}?filterFields=name&filterIsExact=true&filter=${item.name}&preferTab=Class`;
    }
  }

  // Turn: /mdr/cdashig/2-0/scenarios/DA.DenormalizedExample/fields/DADAT
  // Into: /mdr/cdashig/2-0/scenarios/DA.Denormalized?filterFields=name&filterIsExact=true&filter=DADAT&preferTab=Scenario  
  // Turn: /mdr/cdashig/2-0/scenarios/DA.Generic/fields/DADAT
  // Into: /mdr/cdashig/2-0/scenarios/DA.HorizontalGeneric?filterFields=name&filterIsExact=true&filter=[DATESTCD]_DADAT&preferTab=Scenario
  if (newHrefLowered.indexOf('/scenarios/') > -1 && newHrefLowered.indexOf('/fields/') > -1) {
    const parentScenarioLink = item.links.find(l => l.linkName === 'parentScenario');
    if (parentScenarioLink) {
      newHref = `${parentScenarioLink.href}?filterFields=name&filterIsExact=true&filter=${item.name}&preferTab=Scenario`;
    }
  }

  // Turn: /mdr/sdtm/1-7/datasets/CO/variables/CODTC
  // Into: /mdr/sdtm/1-7/datasets/CO?filterFields=name&filterIsExact=true&filter=CODTC
  if (newHrefLowered.indexOf('/datasets/') > -1 && newHrefLowered.indexOf('/variables/') > -1) {
    const parentDatasetLink = item.links.find(l => l.linkName === 'parentDataset');
    if (parentDatasetLink) {
      newHref = `${parentDatasetLink.href}?filterFields=name&filterIsExact=true&filter=${item.name}`;
    }
  }

  // Turn: /mdr/adam/adamig-1-1/datastructures/ADSL/variables/AAGE
  // Into: /mdr/adam/adamig-1-1/datastructures/ADSL/variablesets/SubjectDemographics?filterFields=name&filterIsExact=true&filter=AAGE
  if (newHrefLowered.indexOf('/datastructures/') > -1 && newHrefLowered.indexOf('/variables/') > -1) {
    const parentVariableSetLink = item.links.find(l => l.linkName === 'parentVariableSet');
    if (parentVariableSetLink) {
      const parentHref = parentVariableSetLink.href.replace('varsets', 'variablesets'); // API uses 'varsets'; UI uses 'variablesets'
      newHref = `${parentHref}?filterFields=name&filterIsExact=true&filter=${item.name}`;
    }
  }

  return newHref;
}

export const getCDASHIGLink = (standard: string, version: string, classId?: string, domainId?: string, scenarioId?: string, keepCurrentParams: boolean = false): string => {
  let link = `/mdr/cdashig/${version}`;
  if (classId) link += `/classes/${classId}`;
  if (domainId) link += `/domains/${domainId}`;
  if (scenarioId) link += `/scenarios/${scenarioId}`;
  if (keepCurrentParams) link += getQueryString(window.location);
  return link;
}

export const getSENDIGLink = (standard: string, version: string, classId?: string, datasetId?: string, keepCurrentParams: boolean = false): string => {
  let link = `/mdr/sendig/${version}`;
  if (classId) link += `/classes/${classId}`;
  if (datasetId) link += `/datasets/${datasetId}`;
  if (keepCurrentParams) link += getQueryString(window.location);
  return link;
}

export const getSDTMIGLink = (standard: string, version: string, classId?: string, datasetId?: string, keepCurrentParams: boolean = false): string => {
  let link = `/mdr/sdtmig/${version}`;
  if (classId) link += `/classes/${classId}`;
  if (datasetId) link += `/datasets/${datasetId}`;
  if (keepCurrentParams) link += getQueryString(window.location);
  return link;
}

export const getADaMLink = (standard: string, version: string, dataStructureId?: string, variableSetId?: string, keepCurrentParams: boolean = false): string => {
  let link = `/mdr/adam/${version}`;
  if (dataStructureId) link += `/datastructures/${dataStructureId}`;
  if (variableSetId) link += `/variablesets/${variableSetId}`;
  if (keepCurrentParams) link += getQueryString(window.location);
  return link;
}
