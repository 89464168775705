import { ISearchLink } from "./ILink";

export interface ISearchParams {
  text: string;
  visible: boolean;
  pageSize?: number;
  start?: number;
  activeFacets?: { [facetKey: string]: string[] };
  includeFacets?: boolean;
}

export interface ISearchResponse {
  hits: ISearchResult[];
  facets: {
    [key: string]: ISearchFacet[];
  }
  hasMore: boolean;
  totalHits: number;
}

export interface ISearchResultBase {
  href: string;
  name: string;
  type: string;
  parentHref: string;
  parentName: string;
  parentType: string;  
  label: string;
  links: ISearchLink[];
  [key: string]: any;
}

export interface ISearchResult extends ISearchResultBase{
  "@search.highlights"?: {
    definition?: string[];
    description?: string[];
  }
}

export interface ISearchFacet {
  value: string;
  count: number;
}

export interface ISuggestParams {
  text: string;
  top?: number;
  select?: string[];
}

export interface ISuggestResponse {
  value: ISuggestResult[];
}

export interface ISuggestResult extends ISearchResultBase{
  "@search.text": string;
}

export const FacetKeyLabelMap = {
  "class": "Class",
  "codelist": "Codelist",
  "conceptId": "Concept ID",
  "core": "Core",
  "dataStructure": "Data Structure",
  "dataset": "Dataset",
  "datasetStructure": "Dataset Structure",
  "definition": "Definition",
  "description": "Description",
  "domain": "Domain",
  "effectiveDate": "Effective Date",
  "extensible": "Extensible",
  "href": "Href",
  "label": "Label",
  "name": "Name",
  "parentName": "Parent Name",
  "parentHref": "Parent Href",
  "parentType": "Parent Type",
  "preferredTerm": "NCI Preferred Term",
  "product": "Product",
  "productGroup": "Product Group",
  "registrationStatus": "Registration Status",
  "sdtmTarget": "SDTM Target",
  "simpleDatatype": "Simple Datatype",
  "submissionValue": "Submission Value",
  "synonyms": "Synonyms",
  "type": "Type",
  "valueDomain": "Value Domain",
  "variableSet": "Variable Set",
  "version": "Version",
  "usageRestrictions": "Usage Restrictions",
  "examples": "Examples",
  "roleDescription": "Role Description",
  "notes": "Notes",
  "subClass": "Sub Class"
};