import React from 'react';
import { Link } from 'react-router-dom';
import { getHrefId } from '../../utils/LinkUtils';
import { ILink } from '../../models/ILink';

/**
 * generic interface to associate codelistlinks
 */
 export interface IVMField {
  codelistLinks: string;
}


/**
 * Lambda for rendering codelist links as rows of codelist columns
 * 
 * @param item object field containing codelist 
 * @returns 
 */
export const onRenderCodelistLinks = (item?: IVMField) => {
  if (item && item.codelistLinks) {
    const codelistLinks: string[] = JSON.parse(item.codelistLinks);
    return codelistLinks.map((cl, idx) => {
      const codelistId = getHrefId(cl);
      const packageAndCodelistPath = cl.replace('/mdr/root/ct/', '');
      return (<>
        {idx > 0 ? '; ' : ''}
        <Link key={codelistId} to={`/mdr/ct/latest/packages/${packageAndCodelistPath}`}>{codelistId}</Link>
      </>);
    })
  }
}

/**
 * 
 * @param field 
 * @returns 
 */
export const parseCodelistLinks = (links?: ILink[]) =>{
  return links ? JSON.stringify(links.map(cl => cl.href)) : '';
}