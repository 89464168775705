/**
 * Global app context that can be leveraged from any component. Allows any component to trigger the error message banner, invoke search, see details about the signed-in user, and more.
 * @packageDocumentation 
 */
import React from 'react';
import { ISearchParams } from '../../models/ISearch';
import { AuthService } from '../../services/AuthService';
import { DataService } from '../../services/DataService';

export interface IAppError {
  title: string;
  message: string;
  isUncaught: boolean;
}

export interface IAppContext {
  appError: IAppError;
  searchParams: ISearchParams;
  clearError: () => void;
  setError: (title: string, message: string) => void;
  search: (searchParams: ISearchParams) => void;
  closeSearch: () => void;
  dataService: DataService,
  authService: AuthService,
}

export const defaultAppContext: IAppContext = {
  appError: null,
  searchParams: null,
  clearError: () => {},
  setError: () => {},
  search: () => {},
  closeSearch: () => {},
  dataService: null,
  authService: null,
};

const AppContext = React.createContext<IAppContext>(defaultAppContext);

export default AppContext;