/**
 * Utility to hold response data along with a filename
 */
export class ResponseData<T> {

  public readonly filename: string;

  constructor(response: Response, readonly data: T) {
    this.filename = ResponseData.getFilename(response);
  }

  /**
  * @param response response to a fetch request
  */
  private static getFilename = (response: Response): string => {
    var filename = "";
    var disposition = response.headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  }

}