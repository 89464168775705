import { useContext, useEffect, useState } from "react";
import useAsyncData from "../../../hooks/useAsyncData";
import AppContext from "../../../components/AppContext/AppContext";
import { MISSING_RESOURCE_ERROR } from "../../../services/DataService";
import { ICT, ICTCodelist } from "../../../models/ICT";
import { isLatestVersion, getPackageHrefId } from "../CT";

export type CTData = {
  isLoadingPackages: boolean;
  isLoadingFullPackage: boolean;
  isLoadingCodelist: boolean;
  packages: ICT[];
  fullPackage: ICT;
  codelist: ICTCodelist;
}

export default function useCTData(version: string, packageId: string, codelistId: string): CTData {
  const [ lastFetchedVersion, setLastFetchedVersion ] = useState<string>(null);
  const { setError, dataService } = useContext(AppContext);

  // Fetch Packages
  const fetchPackages = (version: string): Promise<ICT[]> => {
    if (isLatestVersion(version)) return null;
    else {
      return dataService.ct.getPackagesByVersion(version).then(data => {
        setLastFetchedVersion(version);
        return data;
      });
    }
  }
  const { 
    isLoading: isLoadingCurrentPackages, 
    data: currentVersionPackages,
    error: currentPackagesError,
  } = useAsyncData<ICT[]>(null, fetchPackages, [version]);

  // Fetch Latest Packages
  const fetchLatestPackages = (version: string, packageId: string, codelistId: string): Promise<ICT[]> => {
    if (!isLatestVersion(version)) return null;
    else {
      if (codelistId) {
        return dataService.ct.getLatestPackagesForCodelist(packageId, codelistId).then(data => {
          setLastFetchedVersion(version);
          return data;
        });
      }
      else {
        return dataService.ct.getLatestPackages(packageId).then(data => {
          setLastFetchedVersion(version);
          return data;
        });
      }
    }
  }
  const { 
    isLoading: isLoadingLatestPackages, 
    data: latestVersionPackages,
    error: latestPackagesError,
  } = useAsyncData<ICT[]>(null, fetchLatestPackages, [version, packageId, codelistId]);

  // Fetch Full Package by Package Id
  const getPackage = (version: string, packageId: string): Promise<ICT> => {
    if (isLatestVersion(version)) return null;
    else {
      return dataService.ct.getPackage(packageId);
    }
  }
  const { 
    isLoading: isLoadingFullPackage, 
    data: fullPackage,
    error: fullPackageError,
  } = useAsyncData<ICT>(null, getPackage, [version, packageId]);

  // Fetch Codelist Terms by Codelist Id
  const getCodelist = (version: string, packageId: string): Promise<ICTCodelist> => {
    if (isLatestVersion(version)) return null;
    else {
      return dataService.ct.getCodelist(packageId, codelistId);
    }
  }
  const { 
    isLoading: isLoadingCodelist, 
    data: codelist,
    error: codelistError,
  } = useAsyncData<ICTCodelist>(null, getCodelist, [version, packageId, codelistId]);

  // Notify UI of Any Errors
  useEffect(() => {
    const combinedErrors = [currentPackagesError, latestPackagesError, fullPackageError, codelistError].filter(e => e).join('; ');
    if (combinedErrors) {
      setError(`Unable to load data for CT`, combinedErrors);
    }
  }, [currentPackagesError, latestPackagesError, fullPackageError, codelistError]);

  // Verify Package
  useEffect(() => {
    if (!isLoadingCurrentPackages && currentVersionPackages && currentVersionPackages.length > 0 && packageId) {
      const loweredPackageId = packageId.trim().toLowerCase();
      const currentPackage = currentVersionPackages.find(p => getPackageHrefId(p._links.self) === loweredPackageId);
      // Invalid packageId
      if (!currentPackage) setError(`Invalid packageId`, MISSING_RESOURCE_ERROR);
    }
  }, [isLoadingCurrentPackages, currentVersionPackages]);

  const isLoadingPackages = (isLoadingCurrentPackages || isLoadingLatestPackages);
  const packages = (
    version !== lastFetchedVersion
    ? null
    : latestVersionPackages && latestVersionPackages.length > 0 
      ? latestVersionPackages
      : currentVersionPackages && currentVersionPackages.length > 0
        ? currentVersionPackages
        : null
  );

  return {
    isLoadingPackages, isLoadingFullPackage, isLoadingCodelist,
    packages, fullPackage, codelist,
  };
}