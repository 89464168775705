/**
 * Global navigation component that renders product links with collapsible headers.
 * @packageDocumentation 
 */
import React, { useState, useEffect, useContext } from 'react';
import './Navigation.scss';
import { withRouter } from 'react-router-dom';
import { Nav, INavLinkGroup, INavLink } from '@fluentui/react/lib/Nav';
import { ActionButton } from '@fluentui/react/lib/Button';
import { INavItem } from '../../models/INavItem';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import { Stack } from '@fluentui/react/lib/Stack';
import useAsyncData from '../../hooks/useAsyncData';
import { PUBLIC_URL } from '../../config';
import AppContext from '../AppContext/AppContext';
import { SearchBox } from '@fluentui/react/lib/SearchBox';

const Navigation: React.FunctionComponent = () => {
  const [navGroups, setNavGroups] = useState<INavLinkGroup[]>([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const { dataService } = useContext(AppContext);
  const { data: navLinks, isLoading } = useAsyncData<INavItem[]>([], dataService.nav.getNavLinks, []);
  const [currentFilter, setCurrentFilter] = useState<string>('');

  const getIsGroupSelected = (link: INavItem): boolean => {
    // If user goes directly to a product URL without using the Nav pane, we want to expand the correct nav group
    if (link.items && link.items.length > 0) {
      for (let childLink of link.items) {
        if (
          ('href' in childLink
            && window.location.hash.toLowerCase().replace('#', '').startsWith(childLink.href.toLowerCase()))
          || getIsGroupSelected(childLink)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  const navItemToNavLink = (expandAll: boolean): (navItem: INavItem) => INavLink => {
    return (navItem: INavItem): INavLink => {
      let link = {
        name: navItem.shortTitle,
        title: navItem.title,
        url: navItem.href ? `${PUBLIC_URL}/#${navItem.href}` : ""
      }

      if ('href' in navItem) {
        link["key"] = navItem.href;
      }

      if (navItem.items && navItem.items.length > 0) {
        link["links"] = navItem.items.map<INavLink>(navItemToNavLink(expandAll));
        link["isExpanded"] = expandAll === undefined ? getIsGroupSelected(navItem) : expandAll;
        // Forces Fluent to render as a button instead of anchor
        link["onClick"] = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => void {};
        link["collapseAriaLabel"] = `Collapse ${navItem.title}`;
        link["expandAriaLabel"] = `Expand ${navItem.title}`;
      }

      if (navItem.status === "Draft") {
        link["iconProps"] = { iconName: "warning", className: "nav-warning-icon" };
      }
      return link;
    };
  }

  const linkFilter = (link: INavLink): boolean => {
    if (currentFilter) {
      const currentFilterTrimLower = currentFilter.trim().toLowerCase();
      const comparisonProperties = ["key", "name", "title", "url"];
      let filterMatches = 'links' in link && link.links.length > 0;
      for (let comparisonProperty of comparisonProperties) {
        filterMatches = filterMatches || (comparisonProperty in link
          && link[comparisonProperty].toLowerCase().indexOf(currentFilterTrimLower) > -1);
      }
      return filterMatches;
    } else {
      return true;
    }
  }

  const filterLinks = (links: INavLink[]): INavLink[] => {
    // Need to filter leafs nodes first
    for (let link of links) {
      if ('links' in link) {
        link.links = filterLinks(link.links);
      }
    }
    const filteredLinks = links.filter(linkFilter);
    return filteredLinks;
  }

  const buildNavGroups = (expandAll: boolean = undefined): INavLinkGroup[] => {
    return !navLinks ? [] : navLinks.map<INavLinkGroup>((rootLink: INavItem): INavLinkGroup => {

      const links = rootLink.items && rootLink.items.length > 0 ? rootLink.items.map<INavLink>(navItemToNavLink(expandAll)) : [];
      const isGroupSelected = getIsGroupSelected(rootLink);

      return {
        name: rootLink.shortTitle,
        collapseAriaLabel: `Collapse ${rootLink.title}`,
        expandAriaLabel: `Expand ${rootLink.title}`,
        collapseByDefault: expandAll === undefined ? !isGroupSelected : !expandAll,
        links: filterLinks(links)
      }
    });
  }

  const getSelectedKey = (): string => {
    const currentPath = window.location.pathname.toLowerCase();
    let currentChildLink: INavLink = null;
    for (let ng of navGroups) {
      currentChildLink = ng.links.find(nl => currentPath.startsWith(nl.url.toLowerCase()));
      if (currentChildLink) break;
    }
    return currentChildLink ? currentChildLink.url : '';
  };

  const onClickExpandAll = () => {
    setNavGroups(buildNavGroups(!isAllExpanded));
    setIsAllExpanded(!isAllExpanded);
  };

  const renderShimmers = () => {
    const shimmerStyles = {
      shimmerWrapper: 'nav-shimmer-wrapper',
      shimmerGradient: 'nav-shimmer-gradient'
    };
    return (
      <Stack tokens={{ childrenGap: 30, padding: '8px 0 0 0' }}>
        <Shimmer styles={shimmerStyles} shimmerElements={[
          { type: ShimmerElementType.line, width: '60%', height: 10 },
          { type: ShimmerElementType.gap, width: '40%', height: 10 },
        ]} />
        <Stack tokens={{ childrenGap: 20 }} >
          <Shimmer styles={shimmerStyles} shimmerElements={[
            { type: ShimmerElementType.line, width: '80%', height: 12 },
            { type: ShimmerElementType.gap, width: '20%', height: 12 },
          ]} />
          <Shimmer styles={shimmerStyles} shimmerElements={[
            { type: ShimmerElementType.line, width: '60%', height: 12 },
            { type: ShimmerElementType.gap, width: '40%', height: 12 },
          ]} />
          <Shimmer styles={shimmerStyles} shimmerElements={[
            { type: ShimmerElementType.line, width: '70%', height: 12 },
            { type: ShimmerElementType.gap, width: '30%', height: 12 },
          ]} />
          <Shimmer styles={shimmerStyles} shimmerElements={[
            { type: ShimmerElementType.line, width: '50%', height: 12 },
            { type: ShimmerElementType.gap, width: '50%', height: 12 },
          ]} />
        </Stack>
      </Stack>
    )
  };

  useEffect(() => {
    if (navLinks) {
      setNavGroups(buildNavGroups());
    }
  }, [navLinks]);

  useEffect(() => {
    if (navLinks) {
      if (currentFilter) {
        setNavGroups(buildNavGroups(true));
      } else {
        setNavGroups(buildNavGroups(isAllExpanded));
      }
    }
  }, [currentFilter]);

  const NavRouter = withRouter(({ history }) => (
    <Nav
      className="navigation"
      onLinkClick={(event, element) => {
        if (event) event.preventDefault();
        if (element) history.push(element.key);
      }}
      groups={navGroups}
      styles={{
        chevronIcon: ['nav-chevron-icon'],
        chevronButton: ['nav-chevron-button'],
        link: ['nav-link'],
        group: ['nav-group'],
        groupContent: ['nav-group-content'],
        compositeLink: ['nav-composite-link']
      }}
      selectedKey={getSelectedKey()}
    />
  ));

  const onFilterChange = (ev: any, value: string) => {
    if (value) {
      setCurrentFilter(value);
    } else {
      onFilterClear();
    }
  }

  const onFilterClear = () => {
    setCurrentFilter('');
  }

  return (
    isLoading
      ? renderShimmers()
      : <>
        <ActionButton
          iconProps={{
            iconName: 'ExploreContent',
            className: "nav-expand-all-icon"
          }}
          onClick={onClickExpandAll}
          className="nav-expand-all"
        >{isAllExpanded ? 'Collapse' : 'Expand'} All</ActionButton>
        <SearchBox
          className="nav-filter-input"
          ariaLabel="Filter Products"
          placeholder="Filter Products"
          iconProps={{ iconName: 'Filter' }}
          value={currentFilter}
          onChange={onFilterChange}
          onClear={onFilterClear}
        />
        <NavRouter />
      </>
  );
}


export default Navigation;
