/**
 * Global layout component that renders on the left side of the page which is responsible for rendering the Dashboard link, Expand/Collapse button and navigation links component.
 * @packageDocumentation 
 */
import React, { useState } from 'react';
import './SidePanel.scss';
import { Icon } from '@fluentui/react/lib/Icon';
import { Link } from 'react-router-dom';
import Navigation from '../Navigation/Navigation';

export interface ISidePanelProps {
  className?: string;
}

const SidePanel: React.FunctionComponent<ISidePanelProps> = ({ className }) => {
  const [collapsed, setCollapsed] = useState(false);

  const onClickExpander = () => {
    setCollapsed(!collapsed);
  }

  let navClassNames = ['sidepanel'];
  if (collapsed) navClassNames.push('collapsed');
  if (className) navClassNames.push(className);

  return (
    <nav className={navClassNames.join(' ')}>
      <div className="expander" onClick={onClickExpander}>
        <Icon
          iconName={collapsed ? "ChevronRight" : "ChevronLeft"}
          ariaLabel={collapsed ? "Expand Menu" : "Collapse Menu"}
        />
      </div>
      <div className="inner">
        <Link to="/" className="dashboard-link">
          <Icon iconName="Home" />
          <span>Dashboard</span>
        </Link>
        <Navigation />
      </div>
    </nav>
  )
};


export default SidePanel;