/**
 * Component that renders when the user navigates to an invalid route or otherwise would receive an HTTP 404 Not Found.
 * @packageDocumentation 
 */
import React, { useContext } from 'react';
import Section from '../../components/Section/Section';
import { Link } from 'react-router-dom';
import AppContext from '../AppContext/AppContext';

const MissingResource: React.FunctionComponent = () => {
  const { clearError } = useContext(AppContext);
  return (
    <>
      <Section pageTitle={"Resource Not Found"}>
        <p>Unable to locate the specified resource. Please contact the system administrator.</p>
        <Link to="/" onClick={clearError}>Return to dashboard</Link>
      </Section>
    </>
  );
}

export default MissingResource;