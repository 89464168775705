import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../components/AppContext/AppContext";
import { getHrefId } from "../../../utils/LinkUtils";
import { ISDTM } from "../../../models/ISDTM";
import { ISDTMIG } from "../../../models/ISDTMIG";
import { ISENDIG } from "../../../models/ISENDIG";

export default function useSDTMRouteObserver(
  standard: string, version: string, classId: string, datasetId: string, currentItem: ISDTM | ISDTMIG | ISENDIG, 
  getLink: (standard: string, version: string, classId?: string, datasetId?: string, keepCurrentParams?: boolean) => string
) {
  const history = useHistory();
  const { setError } = useContext(AppContext);

  const getDefaultLink = (): string | undefined => {
    let link: string = undefined;
  
    // do we have an item with classes, but don't have either a classId or datasetId in the url?
    if (currentItem && currentItem.classes && (!classId || !datasetId)) {
  
      // no classId and no datasetId
      if (!classId && !datasetId) {
        const newClassId = getHrefId(currentItem.classes[0]._links.self);
        const newDatasetId = currentItem.classes[0].datasets ? getHrefId(currentItem.classes[0].datasets[0]._links.self) : '';
        link = getLink(standard, version, newClassId, newDatasetId, true);
      }
  
      // have classId but no datasetId
      else if (classId && !datasetId) {
        const newClass = currentItem.classes.find(cl => getHrefId(cl._links.self) === classId);
        const newDatasetId = newClass && newClass.datasets ? getHrefId(newClass.datasets[0]._links.self) : '';
        
        // does selected class have a dataset?
        if (newDatasetId) {
          link = getLink(standard, version, classId, newDatasetId, true);
        }
      }
  
      // no classId but have datasetId
      else if (!classId && datasetId) {
        const newClass = currentItem.classes.find(cl => cl.datasets && cl.datasets.some(ds => getHrefId(ds._links.self) === datasetId));
        const newClassId = newClass ? getHrefId(newClass._links.self) : '';
  
        //does selected dataset have a class?
        if (newClassId) {
          link = getLink(standard, version, newClassId, datasetId, true);
        }
      }
    }
  
    return link;
  }

  // Watch Params, Update URL if needed
  useEffect(() => {
    if (currentItem) {
      try {
        const defaultLink = getDefaultLink();
        
        if (defaultLink) {
          history.replace(defaultLink);
        }
      }
      catch (error) {
        setError(`Unable to update SDTM/SDTMIG/SENDIG route`, error);
      }
    }
  }, [version, classId, datasetId, currentItem]);
}
