/**
 * Data service for products requests.
 * @packageDocumentation 
 * 
 * @module DataService-Products
 */
import { BaseDataService } from '../BaseDataService';
import { IProducts } from '../../models/IProducts';

export class Products extends BaseDataService {

  public getProducts = async (): Promise<IProducts> => {
    let result = await this.get<IProducts>(`/api/mdr/products?expand=true`);
    return result;
  }
  
}