import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../components/AppContext/AppContext";
import { getHrefId } from "../../../utils/LinkUtils";
import { ICDASH } from "../../../models/ICDASH";
import { getDomainsForClassId } from "../CDASH";

export default function useCDASHRouteObserver(
  standard: string, version: string, classId: string, domainId: string, currentItem: ICDASH, 
  getLink: (standard: string, version: string, classId?: string, domainId?: string, scenarioId?: string, keepCurrentParams?: boolean) => string
) {
  const history = useHistory();
  const { setError } = useContext(AppContext);

  const getDefaultLink = (): string | undefined => {
    let link: string = undefined;
  
    // do we have an item with classes, but don't have either a classId or domainId in the url?
    if (currentItem && currentItem.classes && (!classId || !domainId)) {
  
      // no classId and no domainId
      if (!classId && !domainId) {
        const newClassId = getHrefId(currentItem.classes[0]._links.self);
        const domainsForClass = getDomainsForClassId(newClassId, currentItem);
        const newDomainId = domainsForClass && domainsForClass.length > 0 ? getHrefId(domainsForClass[0]._links.self) : '';
        link = getLink(standard, version, newClassId, newDomainId, undefined, true);
      }
  
      // have classId but no domainId
      else if (classId && !domainId) {
        const domainsForClass = getDomainsForClassId(classId, currentItem);
        const newDomainId = domainsForClass && domainsForClass.length > 0 ? getHrefId(domainsForClass[0]._links.self) : '';
        
        // does selected class have a domain?
        if (newDomainId) {
          link = getLink(standard, version, classId, newDomainId, undefined, true);
        }
      }
  
      // no classId but have domainId
      else if (!classId && domainId) {
        const domain = currentItem.domains.find(d => getHrefId(d._links.self) === domainId);
        const newClassId = domain ? getHrefId(domain._links.parentClass) : '';
  
        //does selected domain have a class?
        if (newClassId) {
          link = getLink(standard, version, newClassId, domainId, undefined, true);
        }
      }
    }
  
    return link;
  }

  // Watch Params, Update URL if needed
  useEffect(() => {
    if (currentItem) {
      try {
        const defaultLink = getDefaultLink();
        
        if (defaultLink) {
          history.replace(defaultLink);
        }
      }
      catch (error) {
        setError(`Unable to update CDASH/CDASHIG route`, error);
      }
    }
  }, [version, classId, domainId, currentItem]);
}
